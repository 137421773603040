import BlogSideBar from '../Blog/BlogSideBar';
import Header from "../Common/Header";
import Post from "./Post";
import PostBanner from "./PostBanner";
import PostSearch from "./PostSearch";
// import Sidebar from "./Sidebar";



export default function BlogPost() {
    return (
        <>
            <div className="content-wrapper">
                <Header />
                <PostBanner />
                <PostSearch />
                <section className="wrapper bg-light">
                    <div className="container py-14 py-md-16">
                        <div className="row gx-lg-8 gx-xl-12">
                            <Post />
                            {/* <Sidebar /> */}
                            <BlogSideBar/>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}