import BrandBlack from "../../../assets/img/brandBlack.png";
import BrandWhite from "../../../assets/img/brandWhite.png";
import "../../../assets/css/dropdown.css";

export default function Header() {
    return (
        <header className='wrapper bg-soft-primary'>
            <nav className='navbar navbar-expand-lg center-nav transparent navbar-light'>
                <div className='container flex-lg-row flex-nowrap align-items-center'>
                    <div className='navbar-brand w-100'>
                        <a href='/'>
                            <img
                                className='w-15'
                                src={BrandBlack}
                                srcSet={BrandBlack}
                                alt=''
                            />
                        </a>
                    </div>
                    <div className='navbar-collapse offcanvas-nav'>
                        <div className='offcanvas-header d-lg-none d-xl-none'>
                            <a href='/'>
                                <img
                                    src={BrandWhite}
                                    srcSet={BrandWhite}
                                    alt=''
                                />
                            </a>
                            <button
                                type='button'
                                className='btn-close btn-close-white offcanvas-close offcanvas-nav-close'
                                aria-label='Close'
                            />
                        </div>
                        <ul className='navbar-nav'>
                            {/* <li className="nav-item"><a className="nav-link" href="/">Home</a></li> */}
                            <div className='dropdwn'>
                                <li className='nav-link hover-btn'>
                                    InfyU LABS{" "}
                                    <i className='fas fa-angle-right'></i>
                                </li>
                                <div className='dd-content'>
                                    <a href='/'>Home</a>
                                    <a href='/about-us'>About Us</a>
                                    <a href='/team'>Our Team</a>
                                    <a href='/news'>News</a>
                                    <a href='/video'>Videos</a>
                                </div>
                            </div>
                            {/* <li className="nav-item"><a className="nav-link" href="/about-us">About Us</a></li> */}
                            <li className='nav-item'>
                                <a className='nav-link' href='/products'>
                                    Products and Services
                                </a>
                            </li>
                            
                            <li className='nav-item'>
                                <a className='nav-link' href='/solutions'>
                                    Solutions
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-link' href='/blogs'>
                                    Blogs
                                </a>
                            </li>
                            
                            <div className='dropdwn'>
                                <li className='nav-link hover-btn'>
                                    Technologies{" "}
                                    <i className='fas fa-angle-right'></i>
                                </li>
                                <div className='dd-content'>
                                    <a href='/spectroscopy'>Spectroscopy</a>
                                    <a href='/IoT'>IoT</a>
                                    <a href='/cloudComputing'>
                                        Explainable Cloud Computing
                                    </a>
                                </div>
                            </div>
                            <li className='nav-item'>
                                <a className='nav-link' href='/contact'>
                                    Contact Us
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className='navbar-other ms-lg-4'>
                        <ul
                            className='navbar-nav flex-row align-items-center ms-auto'
                            data-sm-skip='true'
                        >
                            <li className='nav-item d-none d-md-block'></li>
                            <li className='nav-item d-lg-none'>
                                <div className='navbar-hamburger'>
                                    <button
                                        className='hamburger animate plain'
                                        data-toggle='offcanvas-nav'
                                        aria-label='button'
                                    >
                                        <span></span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* < div className="navbar-other w-100 d-flex ms-auto" >
                        <ul className="navbar-nav flex-row align-items-center ms-auto" data-sm-skip="true">
                            <li className="nav-item d-none d-md-block">
                            </li>
                            <li className="nav-item d-lg-none">
                                <div className="navbar-hamburger"><button className="hamburger animate plain" data-toggle="offcanvas-nav"><span /></button></div>
                            </li>
                        </ul>
                    </div > */}
                </div>
            </nav>
        </header>
    );
}
