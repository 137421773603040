import Footer from "../Common/Footer";
import Header from "../Common/Header";
import AboutUsHeader from "./AboutUsHeader";
import AboutUsInfo from "./AboutUsInfo";
import { motion } from "framer-motion";

export default function AboutUs() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
            // transition={{ duration: 0.25 }}
        >
            <div className='content-wrapper'>
                <Header />
                <AboutUsHeader />
                <AboutUsInfo />
                <Footer />
            </div>
        </motion.div>
    );
}
