import Header from '../Common/Header';
import PostBanner from "./PostBanner";
import PostSearch from "./PostSearch";
import Post from "./Post";
import BlogSideBar from '../Blog/BlogSideBar';

export default function BlogPost4() {
    return (
        <div className="content-wrapper">
            <Header/>
            <PostBanner/>
            <PostSearch/>
            <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12">
                        <Post/>
                        <BlogSideBar/>
                    </div>
                </div>
            </section>
        </div>
    )
}