import Footer from "../Common/Footer";
import Header from "../Common/Header";
import TeamBanner from "./TeamBanner";
import TeamInfo from "./TeamInfo";
import { motion } from "framer-motion";

export default function Team() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                <TeamBanner />
                <TeamInfo />
                <Footer />
            </div>
        </motion.div>
    );
}
