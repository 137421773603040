


export default function PostSearch() {
    return (
        <section className="wrapper bg-light wrapper-border">
            <div className="container inner py-8">
                {/* <div className="row gx-lg-8 gx-xl-12 gy-4 gy-lg-0"> */}
                <div className="d-flex justify-content-between">
                        <a href="/blog1" className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0 me-1"><i className="uil uil-arrow-left"></i> Prev Post</a>
                        <a href="/blog3" className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end mb-0">Next Post <i className="uil uil-arrow-right"></i></a>
                    </div>
                    {/* <aside className="col-lg-4 sidebar">
                        <form className="search-form">
                            <div className="form-floating mb-0">
                                <input onChange={() => { }} id="search-form" type="text" className="form-control" placeholder="Search" />
                                <label htmlFor="search-form">Search</label>
                            </div>
                        </form>
                    </aside> */}
                {/* </div> */}
            </div>
        </section>
    );
}