import Footer from "../Common/Footer";
import Header from "../Common/Header";
import ProductBanner from "./ProductBanner";
import ProductContent from "./ProductContent";
import ReCAPTCHA from "react-google-recaptcha";
import { motion } from "framer-motion";
// import Screen1 from "../../../assets/img/screen1.png";
// import { useForm } from "../../hooks/useForm";
import $ from "jquery";
import { useRef, useState } from "react";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default function Product() {
    const recaptcharef = useRef();
    //const [inter, handleChange, handleSubmit] = useForm(interested);
    const [enquiry, setEnquiry] = useState({});
    const [message, setMessage] = useState("");

    const url = process.env.REACT_APP_API_URL;
    const captchKey = process.env.REACT_APP_RECAPTCHA_KEY;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEnquiry((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const captchaToken = await recaptcharef.current.executeAsync();
        recaptcharef.current.reset();
        try {
            let res = fetch(`${url}/enquiry`, {
                // let res = fetch("http://16e6-103-66-10-242.ngrok.io/enquiry", {
                method: "POST",
                body: JSON.stringify({
                    enquiry,
                    captchaToken,
                }),
            });
            // console.log(await res);
            if ((await res).ok) {
                setEnquiry({});
                setMessage("Form submitted successfully...");
                // console.log("success");
            } else {
                console.log("some problem");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                <ProductBanner />

                <section className='wrapper bg-light'>
                    <div className='container pb-14 pb-md-16'>
                        <div className='row'>
                            <div className='col-lg-10 mx-auto'>
                                <div className='blog single mt-n17'>
                                    <div className='card'>
                                        {/* <div className='card-img-top'>
    
                                            <div
                                                className='basic-slider owl-carousel dots-over shadow-lg'
                                                data-nav='true'
                                                data-margin={5}
                                            >
                                                <div className='item'>
                                                    <img
                                                        src={Screen1}
                                                        srcSet={Screen1}
                                                        className='rounded'
                                                        alt=''
                                                    />
                                                </div>
                                                <div className='item light-gallery-wrapper'>
                                                    <video
                                                        poster='./assets/img/photos/movie.jpg'
                                                        className='player'
                                                        playsInline
                                                        controls
                                                        preload='none'
                                                    >
                                                        <source
                                                            src='https://www.infyulabs.com/images/1634121178433-WhatsApp Video 2021-08-28 at 3.38.23 PM.mp4'
                                                            type='video/mp4'
                                                        />
                                                    </video>
                                                </div>
                                                <div className='item'>
                                                    <img
                                                        src={Screen1}
                                                        srcSet={Screen1}
                                                        className='rounded'
                                                        alt=''
                                                    />
                                                </div>
                                            </div>
                                            {/* /.basic-slider */}

                                        <div className='card-body'>
                                            <ProductContent />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
            <div
                className='modal fade'
                id='form'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
            >
                <div
                    className='modal-dialog modal-dialog-centered'
                    role='document'
                >
                    <div className='modal-content' style={{ height: "80%" }}>
                        <div className='modal-header'>
                            <h3
                                style={{
                                    margin: "0 auto",
                                    textDecorationLine: "underline",
                                }}
                                className='modal-title'
                            >
                                INTERESTED!
                            </h3>
                            <button
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    marginRight: 10,
                                    marginTop: 5,
                                    border: "none",
                                    color: "#21262C",
                                    backgroundColor: "transparent",
                                    width: "50px",
                                    height: "50px",
                                }}
                                className='closeBB close'
                                type='button'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                {/* <span aria-hidden='true' style={{fontSize: "30px"}}>X</span> */}
                                <span
                                    aria-hidden='true'
                                    style={{ fontSize: "30px" }}
                                >
                                    &times;
                                </span>
                            </button>
                        </div>

                        <div className='tab-content'>
                            <form
                                id='interested'
                                className='tab-pane in active'
                                onSubmit={handleSubmit}
                            >
                                <ReCAPTCHA
                                    ref={recaptcharef}
                                    sitekey={captchKey}
                                    size='invisible'
                                />
                                <div className='modal-body'>
                                    <div className='form-group pt-2'>
                                        <label htmlFor='name1'>Name</label>
                                        <input
                                            name='name'
                                            value={enquiry.name || ""}
                                            onChange={handleChange}
                                            type='text'
                                            className='form-control'
                                            id='name1'
                                            aria-describedby='nameHelp'
                                            placeholder='Enter Name'
                                            required
                                        />
                                        <small
                                            id='nameHelp'
                                            className='form-text text-danger'
                                        >
                                            {}
                                        </small>
                                    </div>
                                    <div className='form-group pt-2'>
                                        <label htmlFor='email1'>Email</label>
                                        <input
                                            name='email'
                                            value={enquiry.email || ""}
                                            onChange={handleChange}
                                            type='text'
                                            className='form-control'
                                            id='email1'
                                            aria-describedby='emailHelp'
                                            placeholder='Enter Email'
                                            required
                                        />
                                        <small
                                            id='emailHelp'
                                            className='form-text text-danger'
                                        >
                                            {}
                                        </small>
                                    </div>
                                    <div className='form-group pt-2'>
                                        <label htmlFor='organization1'>
                                            Organization
                                        </label>
                                        <input
                                            name='organization'
                                            value={enquiry.organization || ""}
                                            onChange={handleChange}
                                            type='text'
                                            className='form-control'
                                            id='organization1'
                                            aria-describedby='organizationHelp'
                                            placeholder='Organization'
                                            required
                                        />
                                        <small
                                            id='organizationHelp'
                                            className='form-text text-danger'
                                        >
                                            {}
                                        </small>
                                    </div>
                                    <div className='form-group pt-2'>
                                        <label htmlFor='units'>
                                            Unit Required
                                        </label>
                                        <input
                                            name='unit'
                                            value={enquiry.unit || ""}
                                            onChange={handleChange}
                                            type='number'
                                            className='form-control'
                                            aria-describedby='unitHelp'
                                            id='units'
                                            placeholder='Unit'
                                            required
                                        />
                                        <small
                                            id='unitHelp'
                                            className='form-text text-danger'
                                        ></small>
                                    </div>
                                    <div className='form-group pt-2'>
                                        <label htmlFor='else'>
                                            Anything Else
                                        </label>
                                        <textarea
                                            name='else'
                                            value={enquiry.else || ""}
                                            onChange={handleChange}
                                            aria-describedby='elseHelp'
                                            type='text'
                                            className='form-control'
                                            id='else'
                                            placeholder='Anything else'
                                            required
                                        />
                                        <small
                                            id='elseHelp'
                                            className='form-text text-danger'
                                        >
                                            {}
                                        </small>
                                    </div>
                                </div>
                                <p className='text-center mt-2 mb-0 text-success'>
                                    {message}
                                </p>
                                <div className='modal-footer border-top-0 d-flex justify-content-center'>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
