import One from '../../../assets/img/transparency.jpg';
import Two from '../../../assets/img/farming.jpg';
import Three from '../../../assets/img/spectroscopy.jpg';

export default function BlogSideBar(){
    return(
        <aside className="col-lg-4 sidebar mt-11 mt-lg-6">
            <div className="widget">
                <h4 className="widget-title mb-3">Popular Posts</h4>
                <ul className="image-list">
                    <li>
                        <figure className="rounded mt-3"><a href="/blog1"><img src="https://www.ukri.org/wp-content/uploads/2021/09/UKRI-270921-FutureFarming-TransformingFoodProduction-Getty-735x490.jpg" alt="" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="/blog1">Traceability in Agriculture Food Supply Chain</a> </h6>
                            <ul className="post-meta">
                                <li className="post-date"><i className="uil uil-calendar-alt" /><span>26 Mar 2021</span></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <figure className="rounded mt-3"> <a href="/blog2"><img src={Two} alt="" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="/blog2">Impact of COVID-19 on Indian Agriculture</a> </h6>
                            <ul className="post-meta">
                                <li className="post-date"><i className="uil uil-calendar-alt" /><span>16 Feb 2021</span></li>
                            </ul>
                        </div>
                    </li>  
                    <li>
                        <figure className="rounded mt-3"> <a href="/blog3"><img src="https://m.economictimes.com/thumb/msid-79459311,width-1200,height-936,resizemode-4,imgsize-283339/agritech.jpg" alt="https://m.economictimes.com/thumb/msid-79459311,width-1200,height-936,resizemode-4,imgsize-283339/agritech.jpg" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="/blog3">How Agritech helped during pandemic</a> </h6>
                            <ul className="post-meta">
                                <li className="post-date"><i className="uil uil-calendar-alt" /><span>16 Feb 2021</span></li>
                            </ul>
                        </div>
                    </li>  
                    <li>
                        <figure className="rounded mt-3"> <a href="/blog4"><img src="https://static.businessworld.in/article/article_extra_large_image/1478073024_Frbdt4_agritech.jpg" alt="https://static.businessworld.in/article/article_extra_large_image/1478073024_Frbdt4_agritech.jpg" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="/blog4">Using agritech to solve the Indian export crisis</a> </h6>
                            <ul className="post-meta">
                                <li className="post-date"><i className="uil uil-calendar-alt" /><span>16 Feb 2021</span></li>
                            </ul>
                        </div>
                    </li>  
                </ul>
            </div>
        </aside>
    )
}