export default function PostBody() {
    return (
        <article className='post'>
            <div className="post-content mb-5" style={{fontFamily: 'Calibri, sans-serif'}}>
                <h2>Using agritech to solve the Indian export crisis</h2>
                <p>
                Contributing to about 15% of India’s GDP, the agricultural sector sustains almost half of the country’s population. With more than 265 mn people engaged in farming, India ranks second in fruits and vegetable production in the world, after China. 
                </p>
                <p>
                As per National Horticulture Database (Second Advance Estimates) published by National Horticulture Board, during 2019-20, India produced 99.07 million metric tonnes of fruits and 191.77 million metric tonnes of vegetables. However, India still has a long way to go in terms of exporting the ‘produce’.
                </p>
                <p>
                According to a research by the Agricultural and Processed Food Products Export Development Authority (APEDA) India only exported produce (fruits and vegetables) worth USD 1,342.14 mn in 2020.
                </p>
                <p>
                Now, this is unsettling because the Indian share in global exports is merely 2% for horticulture produce, and a major constraint is the inability to meet the quality requirements for export.
                </p>
                <p>
                Border rejection of consignments is one of the critical issues faced by Indian exporters. This not only leads to food wastage but also makes India lose market share to exporters from other countries. 
                </p>
                <p>
                In 2019, the European Union (EU) issued border rejection notifications for 147 Indian consignments while the US rejected a total of 1,674 consignments, citing health risk and pest infestation – a common issue faced by Indian food produce, particularly fresh food products. 
                </p>
                <p>
                Another issue is the non-deployment of correct tech devices by the farmers and retailers on various levels of supply chains to ascertain the quality of the produce. 
                </p>
                <p>
                The traditional methods used by the stakeholders to judge the quality and anticipate how long it will take before the produce ripens and rots on the basis of their experience has proved to be futile. 
                </p>
                <p>
                Agritech can resolve these quality analysis issues and enhance the export statistics of India. The devices developed by InfyU LABS ensure quick, non-destructive, and accurate testing methodologies. Infyzer, a handheld device, enables measuring food safety and quality with utmost convenience.
                </p>
                <p>
                This and other "lab in a pocket" kind of solutions can be standardised across the globe to achieve consistent quality merits. Agritech can help importing countries achieve faith and recognize the testing procedures of the exporting country. 
                </p>
                <p>
                Agri-tech is a newfound opportunity that can augment different phases in agriculture.
                </p>
                <p>
                In the planning phase devices such as drones can assist with soil health sampling, seed planting patterns, irrigation strategies and much more.
                </p>
                <p>
                In the pre-harvest phase, digital tools can help recognise areas that require special attention at the appropriate moment when crops are in the growth period. Fertilisers and insecticides can be sprayed in appropriate amounts and precisely in specific locations. 
                </p>
                <p>
                In the post-harvest phase digitised QC can reduce the time consumed by traditional methods which also contributes to food wastage. It can also reduce the extreme reliance on human resources. 
                </p>
                <p>
                Additionally, a combination of spectroscopy and AI-powered applications can lead to measuring internal food quality in terms of nutritional value, contaminants, moisture and sugar content, acidity, soluble solids, pesticide levels, shelf life etc.
                </p>
                <p>
                Measuring quality in terms of external factors like ripeness, texture, appearance, uniformity, firmness, maturity, and freshness can be facilitated by various remote sensing and image processing instruments.
                </p>
            </div>
        </article>
    )
}