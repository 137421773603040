

export default function PostSearch() {
    return (
        <section className="wrapper bg-light wrapper-border">
            <div className="container inner py-8">
                <div className="d-flex justify-content-between">
                    <a href="/blog2" className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0 me-1"><i className="uil uil-arrow-left"></i> Prev Post</a>
                    <a href="/blog4" className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end mb-0">Next Post <i className="uil uil-arrow-right"></i></a>
                </div>
            </div>         
        </section>
    );
}