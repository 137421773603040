
import Process from '../../../assets/img/process.png';


export default function PostBody() {
    return (
        <article className="post">
            <div className="post-content mb-5">

                <div className="cardText" id="display">
                    <span id="docs-internal-guid-c8578e9b-7fff-4eca-a153-68b5206eb839">
                        <p style={{ textAlign: 'center', lineHeight: '1.38', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Why traceability is important in overall
                            perceptive?</span></p>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>Traceability is standardised because the whole
                                supply chain is inter-dependent and all stakeholders are involved since the food chain is very complex.
                                One product can involve several companies from where the ingredients, content and packaging have been
                                supplied. The food chain begins with the origin of the food, ingredients and continues with processing
                                history, definition of the batch, links between manufacturing batches, methods of production, methods of
                                analysis, storage, personnel involved, the entire supply and distribution chain system, etc.
                                Traceability necessitates the establishment of product integrity, authenticity and identification at all
                                the stages including food inspection and certification system so as to build consumer coincidence.
                                Another important objective of traceability is to ensure safe food and prevention of unfair trade
                                practices and for these objectives to be fulfilled it is important to have in place Food Safety
                                Management System (FSMS) and record maintenance.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Introduction:</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '12pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}>Before going to WHAT should be done&amp; WHY should do Traceability in each
                            node of food supply chain, first we should understand the nodes in chain.</span></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.38', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '12pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}><div className="mb-12">
                                <figure className="hover-scale rounded"><a href="./assets/img/photos/b10-full.jpg" className="lightbox"> <img src={Process} alt="" /></a></figure>
                            </div></span></p>
                        <p dir="ltr" style={{
                            lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}> </span></p>
                        <p dir="ltr" style={{
                            lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>Typical Farm2Fork
                                supply chain </span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>The major nodes in the food supply chains, from
                            its origin to the consumption are:</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Farms</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Farm Aggregator (Storage / Market)</span>
                                </p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Food Processor</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Wholesaler or Distributor</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Exporter</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>

                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Retailers</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Consumer</span></p>
                            </li >
                        </ul ><br />
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}><b><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}>What</span><span style={{
                                fontSize: '14pt',
                                fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}> should be
                                traced in different nodes of the chain?</span></b></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.38', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Farm level&nbsp</span></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.38', marginTop: '0pt', marginBottom: '10pt' }}><img
                            alt="fffd"
                            src="https://www.infyulabs.com/images/1599146242963-maximum-yield-gruetzmacher-ai-automation-greenhouse-controlled-environment-agriculture.jpg"
                            style={{ width: '100%', /*height: '365.277px'*/ }} className="blog-img" /><br /></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '12pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}>Farmers nothing but the growers, they should maintain the records some
                            essential information related to production of the product</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}><b>Crop protection
                                        materials</b>&nbsp;</span>&nbsp;<span style={{
                                            fontSize: '12pt',
                                            fontFamily: '-apple-system, BlinkMacSystemFont, segoe ui, roboto, helvetica neue, arial,sans-serif'
                                        }}>&nbsp;&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                        fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Seed
                                        information</span><span style={{
                                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                        }}>, </span><span style={{
                                            fontSize: '12pt',
                                            fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                        }}><span
                                            className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
                                        </span></span><span style={{
                                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                        }}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    </span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}><b>Fertilizers,</b></span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols ,sans-serif'
                                , backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}><b>Soil&nbsp; Health</b></span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                        fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Packaging
                                        material</span><span style={{
                                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                        }}>, -Type of material whether is it gunny
                                        bags, plastic, cardboard boxes etc.,</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                        fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Harvesting,</span><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}> - Date of harvest, Mechanical or Manual
                                        harvesting</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                        fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Water
                                        source</span><span style={{
                                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                        }}>- either through irrigation, rain fed
                                        etc.,</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                                        fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>All other
                                        day to day activities in farm location&nbsp;</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>We should record</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>A unique ID code will be generated while
                                        recording the data. Attributes include</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Geo locating the land</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Farmer, Farm and Crop information</span>
                                </p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Package and Practices&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Contact details</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Farm Registration Number&nbsp;</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>What is the risk and why?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>It is
                                        important to grow the crops recommended as per the prevailing
                                        agro-climatic conditions in the region. Failing which the crop may suffer.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Difference in
                                        temperature, water variations, stress and lack of the
                                        required soil nutrients push the farmer to take help of external sources like chemical based
                                        fertilizers.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Use of
                                        inappropriate or unapproved crop varieties where it is illegal
                                        to use.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Use of ad hoc
                                        or incorrect cultivation practices</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Incorrect
                                        pest and disease management such as use of banned chemicals,
                                        high dosage, spraying the chemical directly on the produce, untrained labor, disposal of the
                                        residue bottle/container in the water stream, etc.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Harvesting of
                                        the produce immediately after pesticide spray,
                                        etc.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Use of wax to
                                        protect the food wastage or use of the chemical to
                                        expedite the ripening.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Majority of
                                        the farms being small scale, there is lack of resources to
                                        bring them under food safety standard compliance. Also monitoring and documenting the entire
                                        farm operation becomes difficult.</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>How
                                digitalization helps?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Digitization
                                        helps in enhancing the farmer knowledge about the correct
                                        and recommended practices. Best Practices and Frequently Asked Questions (FAQ) help in
                                        identification of the correct seeds, nutrients and recommended pest management practices. This
                                        helps in multi-media (photo, audio and video based) learning on the mobile phones, in local
                                        language.</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Use of the
                                        photo gallery is very crucial in IPM. It helps in
                                        identification of the good and bad bugs (pests). Hence, it helps in the appropriate use of the
                                        pesticides only when the pests count is above the threshold (known as ETL).&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Uploading the
                                        soil testing reports for every plot in the web and
                                        expert recommendations on the relevant farmer queries, helps personalize the farm
                                        advisory.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Pest
                                        management best practices include the list of the new and widely
                                        used recommended chemicals with the required warning about the maximum dosage. It also helps
                                        disseminate the information about the banned chemicals.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Digital
                                        Record-keeping helps in generation of the periodic reports,
                                        which can be submitted for the crop certification too.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Based on the
                                        pest management data entered, it can help alert on the
                                        recommended harvesting date.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>At the
                                        advanced level and for large farms the technologies like soil
                                        and plant sensors, automatic weather stations, Radio Frequency Identification (RFID), Global
                                        Positioning System (GPS), etc. can be used to monitor multiple farm and weather materials and
                                        helps in analysing their impact on the food quality and safety.&nbsp;</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>What Output we can get by doing above
                            operations?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>It helps in keeping the total chemical
                                        consumption&nbsp; per unit of the area and hence, able to generate the training records of the
                                        labor,&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Date and time of the spray and the time
                                        gap between the spray and the harvest, etc.&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>The digital photo helps in keeping the
                                        record of each farm operations, stamped by the system date and time.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>The farm can be traced in the map and can
                                        also be easily linked with the QR code. Hence, origin of the food is easily traceable.</span>
                                </p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '0pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Digitization helps in a “traceable”
                                        artefacts system, which is good for the crop certification processes like Good Agricultural
                                        Practices (GAP).</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}
                                    role="presentation"><span style={{
                                        fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                                        backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                        verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                    }}>Easy reference and archival helps develop
                                        a history of the records for the farm. Learning from each season is fed into the system and the
                                        improvement can be tracked too.</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Traceability at Aggregator Level
                            (Packhouse)</span></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><img
                            alt="ffs" src="https://www.infyulabs.com/images/1599146230872-1571326258-5483.jpg" style={{
                                width: '100%',
                                /*height: '382.426px'*/
                            }} className="blog-img" /><span style={{
                                fontSize: '14pt',
                                fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontWeight: 700,
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}><br /></span></p>
                        <p dir="ltr" style={{ lineHeight: '1.7999999999999998', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                backgroundColor: 'transparent', fontFamily: 'Calibri, sans-serif', fontSize: '14pt',
                                fontWeight: 700, whiteSpace: 'pre-wrap', textAlign: 'justify'
                            }}>What is the Risk and why?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>An aggregator
                                        collects the food from the various farms and stores it
                                        at a warehouse or market yard, before it is sent to the processor or the buyer.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Since the
                                        farm operations and the plot conditions vary from farmer to
                                        farmer (or the plot by plot), it is important to maintain the separation of the produce at the
                                        warehouse. This is one of the (potentially) biggest nodes, where the mixing of the produce may
                                        occur, hampering the entire traceability process.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>An integrated
                                        harvesting and storage calendar schedule can help
                                        improve the inflow of the material (produce) at the storage as well as the outflow. This also
                                        helps the effective utilization of space as well as lesser storage duration required for the
                                        produce.</span></p>
                            </li >
                        </ul ><br />
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', marginLeft: '18pt', textAlign: 'justify',
                            marginTop: '0pt', marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt',
                            fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontWeight: 700,
                            fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>To avoid risk and to make sure transparency</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Growers
                                        deliver product in bulk using various containers or logistics
                                        units for transport.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Common
                                        examples of logistics units include totes, bins and
                                        trailers.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Each Logistic
                                        Unit must be individually traceable.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>For this
                                        reason, each Logistic Unit carries a tag or label that shows
                                        a unique identification number.</span></p>
                            </li>
                        </ul>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>System
                                        generated plot code labels can be affixed on the large crate
                                        having the produce from the same plot.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>CCTV cameras
                                        also help monitor the process remotely and keep an audit
                                        trail for the entire process.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Some of the
                                        sensors such as weighing machines, room temperature,
                                        moisture level, etc. can help give the automatic data about the inflow/outflow as well as the
                                        storage environment conditions.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>What to be
                                recorded?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>To enable
                                        traceability, packers/repackers must also maintain records
                                        of other product inputs (e.g.packaging material, packing line information, etc.). This
                                        information is equally critical to your company’s body of internal traceability
                                        information.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Commodity
                                        name and, where applicable, variety name, procured date from
                                        grower.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Additional
                                        grower/harvest information.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>The grower’s
                                        unique company identification.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Receipt date,
                                        Ship date (from previous trading partner), sender’s
                                        identification, sender’s ship from location (from next trading partner), Receivers
                                        identification, ship to location.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Trade item,
                                        quantity, unit of measure, and other description.</span>
                                </p>
                            </li>
                        </ul><br />
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', marginLeft: '18pt', textAlign: 'justify',
                            marginTop: '0pt', marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt',
                            fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontWeight: 700,
                            fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                        }}>Output</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Farmer and
                                        plot wise produce-quantity reports, uniquely identified by
                                        the QR code can help maintain the food segregation and support traceability.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Traceability at Food Processor Level</span></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><img
                            alt="vv" src="https://www.infyulabs.com/images/1599146221152-COVID-19-affected-Chihuahuas-processing-industry.jpg"
                            style={{ width: '100%', /*height: '347.498px'*/ }} className="blog-img" /><span style={{
                                fontSize: '14pt',
                                fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontWeight: 700,
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}><br /></span></p>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            backgroundColor: 'transparent', fontFamily: 'Calibri, sans-serif',
                            fontSize: '14pt', fontWeight: 700, whiteSpace: 'pre-wrap'
                        }}>What is the Risk and why?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Similar to
                                        the food aggregator, food processor gets the food inflow
                                        from multiple sources; this increases the risk of mixing of the food.&nbsp;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Food
                                        processors either repackage the food by sorting, grading and
                                        repacking them into smaller packets or pallets, or process or transform them into a different
                                        value added products like juice.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>There is risk
                                        of usage of chemicals for ripening of the food or use of
                                        preservatives. Hence it is important to keep a record of such usage and need to highlight the
                                        same on the packets.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>How can
                                Digitisation help in this?</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>RFID
                                        technologies can be used on the produce and the pallets
                                        containing the produce. Sensor data helps in managing the cooling temperature whenever it rises
                                        above the thresholds. Various reports helps establish the tracking of the foods inflow and
                                        outflow between premises.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '18pt' }}>

                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Pallets are
                                        grouped and put into a bigger box, which too has the
                                        required labelling. These boxes are loaded in large frames. All these information are entered
                                        and maintained in the digital system. Hence, enabling a tracking of the frame to box, box to
                                        pallets/packs, and packs to plot. This enables an end to end tracking of the food</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>Output</span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>System
                                        generated plot code labels can be affixed on the produce. The
                                        packaging labels must have the details of the processors, date of processing and also have the
                                        QR code, containing all the farm level details or pointers.</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Tracking
                                        helps in finding the quality of the food and rejection at
                                        multiple levels due to inadequate quality standards or compliance.</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>The inflow
                                        and outflow of the produce from the processing bench helps
                                        understand the difference between the raw and the processed food, indicating the wastage.</span>
                                </p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }} >
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>This can also
                                        help analyze the quantity of the wastage and help
                                        minimize.</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><span
                            style={{
                                fontSize: '14pt', fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent',
                                fontWeight: 700, fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                                verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                            }}>Traceability at retail store</span></p>
                        <p dir="ltr" style={{ textAlign: 'center', lineHeight: '1.8', marginTop: '0pt', marginBottom: '10pt' }}><img
                            alt="bb" src="https://www.infyulabs.com/images/1599146212869-truy-xuat-nguon-goc-thuc-pham-540x360.jpg"
                            style={{ width: '100%', /*height: '363.658px'*/ }} className="blog-img" /><span style={{
                                fontSize: '14pt'
                                , fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontWeight: 700,
                                fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap'
                            }}><br /></span></p>
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Retail stores
                                        must capture traceability information from the products
                                        (Consumer units) sent by their suppliers.</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Normally
                                        retailers need to cut and re-pack fresh produce that arrive
                                        from their suppliers in large sizes or in bulk (e.g. rice, flour, sugar, beans). Each time there
                                        is a need to identify these products it is necessary to use batch/lot number. The batch/lot
                                        number is determined by the trading party that created the individual trade item.</span></p>
                            </li >
                        </ul >
                        <ul style={{ marginBottom: '0px' }}>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Retailer’s
                                        name</span></p>
                            </li>
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Retailer
                                        BID</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Trade item
                                        identifier (Batch or Lot number)</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Trade item
                                        description, quantity and unit of measure&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Shipped
                                        from&amp; to location identification&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Date of
                                        shipment &amp; receipt&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Sales Receipt
                                        number&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '0pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Name of
                                        person who dispatched the product&nbsp;</span></p>
                            </li >
                            <li dir="ltr" style={{
                                listStyleType: 'disc', fontSize: '12pt', fontFamily: 'noto sans symbols,sans-serif'
                                , backgroundColor: 'transparent', fontVariantNumeric: 'normal', verticalAlign: 'baseline',
                                whiteSpace: 'pre'
                            }}>
                                <p dir="ltr" style={{
                                    lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                                    marginBottom: '10pt'
                                }} role="presentation"><span style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Calibri, sans-serif', backgroundColor: 'transparent', fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                                }}>Name of
                                        person who received the product&nbsp;</span></p>
                            </li >
                        </ul >
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '14pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontWeight: 700, fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>Traceability at
                                Consumer Level</span></p>
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '12pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>Consumers use the QR code to trace the food
                                origin. This same information can help the regulatory authorities like FSSAI to recall the unsafe foods.
                                The package information helps in tracing the food source, and hence can be traced back.</span></p><br />
                        <p dir="ltr" style={{
                            lineHeight: '1.7999999999999998', textAlign: 'justify', marginTop: '0pt',
                            marginBottom: '10pt'
                        }}><span style={{
                            fontSize: '11pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}>&nbsp;</span></p>
                        <div style={{ textAlign: 'center' }}><span style={{
                            fontSize: '11pt', fontFamily: 'Calibri, sans-serif',
                            backgroundColor: 'transparent', fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal',
                            verticalAlign: 'baseline', whiteSpace: 'pre-wrap'
                        }}><br /></span></div>
                    </span >
                </div >
            </div >
        </article >
    );
}