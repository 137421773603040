import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { motion } from "framer-motion";

import BG from "../../../assets/img/photos/aggregators.webp";
import aggr from "../../../assets/img/Aggregators.png";

export default function Aggregators() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                {/* <section className="wrapper bg-soft-primary"> */}
                <section
                    className='wrapper image-wrapper bg-image bg-overlay bg-overlay-400'
                    data-image-src={BG}
                    style={{ backgroundPosition: "bottom" }}
                >
                    <div className='container pt-16 pb-16 pt-md-16 pb-md-16 text-center'>
                        <div className='row'>
                            <div className='col-md-7 col-lg-6 col-xl-5 mx-auto'>
                                <h1 className='display-1 mb-3 text-white'>
                                    Aggregators and Cold Storages
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" data-image-src={BG}> */}
                <section className='wrapper bg-white'>
                    <div className='col-md-8 col-lg-8 mx-auto'>
                        <div className=' py-2 mx-4'>
                            <div className='row gy-10 mt-0 align-items-center'>
                                <div className='col-lg-8'>
                                    <h3 className='display-6 mb-4'>
                                        InfyU LABS Aiding Aggregators
                                    </h3>
                                    <p className='mb-5'>
                                        Aggregators are very important players
                                        of the agricultural landscape. They are
                                        centralized bridges which connect
                                        farmers and sellers. Inward flow of
                                        products from several sources get
                                        accumulated and are supplied to various
                                        small and large sellers via aggregators.
                                    </p>
                                    <p className='mb-5'>
                                        InfyU LABS quality assurance solution
                                        can be a game changer for the aggregator
                                        community.
                                    </p>
                                </div>
                                <div className='col-lg-4 position-relative'>
                                    <figure className='rounded mb-0'>
                                        <img src={aggr} srcSet={aggr} alt='' />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className=' py-2 mx-4'>
                            <h3 className='display-6 mb-4'>
                                How Buying and Selling will become easier
                            </h3>
                            <p className='mb-5'>
                                When aggregators buy the produce from farmers
                                they carry out quick hasslefree quality checks
                                on the spot using InfyU LABS' Infyzer. By merely
                                tapping on their phone screens it is possible to
                                have a customized quality report as per their
                                need on Infyzer’s application. This will help
                                them buy desired quality produce at fair rates.
                            </p>
                            <p className='mb-5'>
                                They can not only buy at fair prices but sell at
                                best rates too. While selling the produce to the
                                players downstream they will hold negotiating
                                power as they will have a ready quality report
                                to show.
                            </p>
                            <p className='mb-5'>
                                Aggregators not only sell locally but also
                                export fruits and vegetables internationally. It
                                is worth noting that India is one of the leading
                                fresh fruit exporters in the world.
                            </p>
                            <p className='mb-5'>
                                A standard quality report with all relevant
                                parameters judged and determined, can help
                                exporters get their consignments pass global
                                quality standards and get accepted worldwide.
                            </p>
                        </div>

                        <div className=' py-2 mx-4'>
                            <h3 className='display-6 mb-4'>
                                Simple Grading, Sorting and Categorizing
                            </h3>
                            <p className='mb-5'>
                                In most cases aggregators judge the fruit or
                                vegetable’s freshness, quality and life based on
                                appearance combined with their experience. This
                                method leaves room for inaccuracy and errors
                                rendering entire consignments useless by the
                                time it reaches the destination.
                            </p>
                            <p className='mb-5'>
                                Additionally, fruits like Kiwi, Alphonso etc.,
                                are difficult to judge based on look and feel as
                                they maintain consistent external texture
                                throughout their life.
                            </p>
                            <p className='mb-5'>
                                These issues can be easily addressed with help
                                of Infyzer’s chemical composition and shelf-life
                                prediction report.
                            </p>
                            <p className='mb-5'>
                                This will help them grade the fruit based on
                                it’s quality. Accurate shelf-life prediction
                                will also make it easy to categorize produce
                                that will last longest and can be transported to
                                farther locations and the lot that need to be
                                sent to nearby local markets. Aggregators will
                                not have to partner with testing labs and wait
                                hours for test results.
                            </p>
                            <p className='mb-5'>
                                Because of a report that is trusted by all
                                parties involved in the end to end supply chain,
                                issues like lack of trust and disagreement on
                                rates can also be resolved.
                            </p>
                            <p className='mb-5'>
                                With InfyU LABS solution we can make a better,
                                more transparent and inclusive agricultural
                                community.
                            </p>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </motion.div>
    );
}
