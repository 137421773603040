import { useState } from "react";
import FarmerGraphic from "../../../assets/img/farmrgraphic-2.webp";
import PlantScan from "../../../assets/img/plantscan-2.webp";

export default function ServiceOffered() {
    const [btnText, setBtnText] = useState("Show More");
    const handleBtn = () => {
        if (btnText === "Show More") {
            setBtnText("Show Less");
        } else {
            setBtnText("Show More");
        }
    };

    return (
        <section className='wrapper bg-light'>
            <div className='container py-12 py-md-12'>
                <div className='row text-center'>
                    <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                        <h2 className='fs-15 text-uppercase text-primary mb-3'>
                            What We Do?
                        </h2>
                        <h3 className='display-4 mb-10 px-xl-10'>
                            At InfyU LABS we innovate the technologies exploring
                            the field of Agriculture.
                        </h3>
                    </div>
                </div>

                <div className='position-relative mb-14 mb-md-17'>
                    <div
                        className='shape rounded-circle bg-soft-primary rellax w-16 h-16'
                        data-rellax-speed='1'
                        style={{
                            bottom: "-0.5rem",
                            right: "-2.5rem",
                            zIndex: 0,
                        }}
                    ></div>
                    <div
                        className='shape bg-dot primary rellax w-16 h-17'
                        data-rellax-speed='1'
                        style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
                    ></div>
                    <div
                        className='row gx-md-5 gy-5 text-center'
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='fas fa-recycle'></i>
                                    </div>
                                    <h4>Reducing Wastage</h4>
                                    <p className='mb-2'>
                                        Through a non-destructive method for
                                        internal quality analysis of perishable
                                        products.
                                    </p>
                                    {/* <a href="/#" className="more hover link-primary">Learn More</a> */}
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='uil uil-clock-two'></i>
                                    </div>
                                    <h4>Quick Testing</h4>
                                    <p className='mb-2'>
                                        By determining the complete internal
                                        quality of a perishable product in 5
                                        seconds with a single scan.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='uil uil-clock-two'></i>
                                    </div>
                                    <h4>Cost-Effective</h4>
                                    <p className='mb-2'>
                                        By increasing sample testing size of
                                        perishables by 10 times at the same
                                        cost.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='uil uil-laptop-cloud'></i>
                                    </div>
                                    <h4>Customized Care</h4>
                                    <p className='mb-2'>
                                        We customize our services according to
                                        the clients' needs and requirements.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='uil uil-cloud-upload'></i>
                                    </div>
                                    <h4>Regular Updates</h4>
                                    <p className='mb-2'>
                                        Our Cloud Solution provides regular
                                        updates to our customers
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-xl-3'>
                            <div
                                className='card'
                                style={{ minHeight: "310px" }}
                            >
                                <div className='card-body'>
                                    <div className='icon btn btn-circle btn-lg btn-primary disabled mb-3'>
                                        <i className='uil uil-chart-line'></i>
                                    </div>
                                    <h4>Advancements</h4>
                                    <p className='mb-2'>
                                        We constantly update and innovate
                                        technologies to make a higher difference
                                        in the agriculture sector.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-14 mb-md-17'>
                    <div className='col-lg-7'>
                        <figure>
                            <img
                                className='w-auto'
                                src={FarmerGraphic}
                                srcSet={FarmerGraphic}
                                alt='farmer'
                                loading='lazy'
                            />
                        </figure>
                    </div>

                    <div className='col-lg-5'>
                        <h2 className='fs-15 text-uppercase text-primary mb-3'>
                            Why Choose Us?
                        </h2>
                        <h3 className='display-6 mb-7'>
                            {/* Our team keeps on innovating with the ideas towards
                            making the field of agriculture more reliable and
                            resourceful. */}
                            We have a team of dedicated professionals from
                            various fields of engineering, working towards
                            making agriculture more reliable and resourceful
                            through constant innovation.
                        </h3>

                        {/* <div className='card plain'> */}
                            {/* <div className='card-body'> */}
                                {/* <p>
                                    The team of InfyU LABS is of dedicated
                                    professionals from various fields of
                                    engineering focused on providing IoT based
                                    solutions in the field of AgriTech for
                                    quality assessment of the Fruits and
                                    Vegetables.
                                </p> */}
                                <p>
                                    InfyU LABS is focused on providing IoT based
                                    solutions in the field of AgriTech for
                                    quality assessment of fruits and vegetables.
                                </p>
                                <p>
                                    If you choose us, you choose quality, better
                                    future and a robust outlook towards AgTech.
                                </p>
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>

                <div className='row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-md-15'>
                    <div className='col-lg-7 order-lg-2'>
                        <figure>
                            <img
                                className='w-auto'
                                src={PlantScan}
                                srcSet={PlantScan}
                                alt='plant scan'
                                loading='lazy'
                            />
                        </figure>
                    </div>

                    <div className='col-lg-5'>
                        <h2 className='fs-15 text-uppercase text-primary mb-3'>
                            Our Solutions
                        </h2>
                        <h3 className='display-6 mb-5'>
                            Our devices such as Infyzer make quality assessment
                            easier than ever before!
                        </h3>
                        <p>
                            As opposed to the traditional way of testing, we
                            test fruits like: apple, pomegranate, kiwi, dragon
                            fruit, orange, sweet lime, pear, muskmelon,
                            watermelon, mango, banana, etc. without destroying them. The
                            conventional way of testing the fruits causes large
                            scale wastage all across the globe.
                        </p>
                        <p>
                            Infyzer helps you do a complete analysis of fruit's
                            internal quality based on the following factors:
                        </p>
                        {/* <p className='mb-6'>
                            Including Apple, Pomegranate, Kiwi, Dragon Fruit,
                            Orange, Sweet Lime, Pear, Musk Melon, Water Melon,
                            without destroying them, which was the traditional
                            way of testing the fruits causing large scale
                            spoilage all across the globe.
                            <br />
                            <br />
                            Here with our device we are able do the complete
                            analysis of fruit’s internal quality consisting of:
                        </p> */}
                        <div className='row'>
                            <div className='col-xl mt-2'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <i className='fas fa-angle-double-right'></i>
                                        TSS (BRIX)
                                    </li>
                                    <li>
                                        <i className='fas fa-angle-double-right'></i>
                                        Acidity (pH)
                                    </li>
                                    <div className='collapse mt-2' id='list'>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Maturity Level
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Starch Index
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Water Core
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Chilling Effect
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Internal Color (Pomegranate)
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <div className='col-xl mt-2'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <i className='fas fa-angle-double-right'></i>
                                        Firmness
                                    </li>
                                    <li>
                                        <i className='fas fa-angle-double-right'></i>
                                        Shelf Life
                                    </li>
                                    <div className='collapse mt-2' id='list'>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Ripeness
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Internal Spoilage
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Moisture Content
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            Dry Matter
                                        </li>
                                        <li>
                                            <i className='fas fa-angle-double-right'></i>
                                            SHU Level (Red Chili)
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <a
                                className='pt-4'
                                role='button'
                                data-toggle='collapse'
                                data-target='#list'
                                aria-expanded='false'
                                aria-controls='list'
                                onClick={handleBtn}
                            >
                                {btnText}
                            </a>
                        </div>
                        {/* <div className='row gy-1'>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>TSS (BRIX).</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Acidity (pH).</span>
                                    </li>
                                </ul>
                            </div>

                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Firmness.</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Shelf Life.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span> Maturity Level.</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Starch Index.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span> Ripeness.</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Internal Spoilage.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span> Water Core.</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Chilling Effect.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Moisture Content.</span>
                                    </li>
                                    <li className='mt-1'>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>Dry Matter.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>
                                            Internal Color (Pomegranate).
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-6'>
                                <ul className='icon-list bullet-bg bullet-soft-primary mb-0'>
                                    <li>
                                        <span>
                                            <i className='fas fa-angle-double-right'></i>
                                        </span>
                                        <span>SHU Level (Red Chili).</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}
