export default {
    "Apple": {
        "Brix": {
            data: [6, 9, 13, 20],
            remarks: ["Somewhat Sweet", "Sweet", "Very Sweet"],
        },
        "Firmness": {
            data: [7, 12, 17, 28],
            remarks: ["Somewhat Crunchy", "Crunchy", "Very Crunchy"]
        },
        "pH": {
            data: [1.5, 2.5, 4.5, 7],
            remarks: ["Sour", "Somewhat Sour", "Less Sour"]
        },
        "Starch": {
            data: [2, 3, 4, 5],
            remarks: ["Somewhat Ripe", "Ripe", "Over Ripe"]
        }
    },
    "Pomegranate": {
        "Brix": {
            data: [7, 9, 12, 15],
            remarks: ["Somewhat Sweet", "Sweet", "Very Sweet"],
        },
        "Firmness": {
            data: [6, 8, 10, 14],
            remarks: ["Somewhat Crunchy", "Crunchy", "Very Crunchy"]
        },
        "pH": {
            data: [2, 3, 4.5, 7],
            remarks: ["Sour", "Somewhat Sour", "Less Sour"]
        },
    },
    "Pear": {
        "Brix": {
            data: [11, 13.5, 16, 18],
            remarks: ["Somewhat Sweet", "Sweet", "Very Sweet"],
        },
        "Firmness": {
            data: [2, 4, 6.5, 8.5],
            remarks: ["Somewhat Crunchy", "Crunchy", "Very Crunchy"]
        },
        "pH": {
            data: [1.5, 2.5, 4.5, 7],
            remarks: ["Sour", "Somewhat Sour", "Less Sour"]
        },
    },
    "Orange": {
        "Brix": {
            data: [6.5, 8.5, 11, 14],
            remarks: ["Somewhat Sweet", "Sweet", "Very Sweet"],
        },
        "Firmness": {
            data: [2.5, 3.5, 5, 8],
            remarks: ["Somewhat Crunchy", "Crunchy", "Very Crunchy"]
        },
        "pH": {
            data: [1.5, 2.5, 4, 7],
            remarks: ["Sour", "Somewhat Sour", "Less Sour"]
        },
    },
    "Sweetlime": {
        "Brix": {
            data: [7, 8.5, 10, 12],
            remarks: ["Somewhat Sweet", "Sweet", "Very Sweet"],
        },
        "Firmness": {
            data: [4, 5, 6.5, 7.5],
            remarks: ["Somewhat Crunchy", "Crunchy", "Very Crunchy"]
        },
        "pH": {
            data: [2, 3, 4, 7],
            remarks: ["Sour", "Somewhat Sour", "Less Sour"]
        },
    }
}