export default function VideosPage({page}){
    if(page===1){
        return(
            <>
                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title="Infyzer"
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/g4qUoFoIrEY"
                            loading='lazy'
                        />
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        {/* <figure style={{ height: "250px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='#'>
                                <img
                                    src='https://images.unsplash.com/photo-1642010973463-0865e09b8db9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
                                    alt=''
                                />
                            </a>
                        </figure> */}
                        <iframe
                            height={250}
                            width= "auto"
                            title='Bluetooth activation with infyzer'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/4amBK2OR3zg"
                            loading='lazy'
                        />
                        {/* <div className='card-body'>
                            <div className='post-header'>
                                <h2>Lorem, ipsum dolor.</h2>
                            </div>
                        </div> */}
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='perishables testing'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/Mg7Ghyb6aq8"
                            loading='lazy'
                        />
                    </div>
                </article>
                
                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='Apple testing with Infyzer vs testing by traditional method'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/eKu_oYQGPws"
                            loading='lazy'
                        />
                    </div>
                </article>         
                
            </>
        )
    }
    else if(page===2){
        return(
            <>
                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='infyzer intro video'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/Rv8nATxpZeE"
                            loading='lazy'
                        />
                    </div>
                </article>  

                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='Infyzer accuracy comparison with traditional method'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/F3391ivwbSw"
                            loading='lazy'
                        />
                    </div>
                </article>  

                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='infyzer app tour'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/HvNo8Q1sMQY"
                            loading='lazy'
                        />
                    </div>
                </article>  

                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='apple trent'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/787gqA2WRhk"
                            loading='lazy'
                        />
                    </div>
                </article>  
                
            </>
        )
    }
    else if(page===3){
        return(
            <>
                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='apple testing adani'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/3rDVn3fpzQc"
                            loading='lazy'
                        />
                    </div>
                </article>  

                <article className='item post col-md-6'>
                    <div className='card'>
                        <iframe
                            height={250}
                            width= "auto"
                            title='apple lot testing'
                            allowFullScreen
                            frameBorder={0}
                            style={{borderRadius: "0.5rem"}}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src="https://www.youtube.com/embed/j-AS0NWxn38"
                            loading='lazy'
                        />
                    </div>
                </article>  

                <article className='item post col-md-6'>
                    
                </article>
                
                <article className='item post col-md-6'>

                </article>
            </>
        )
    }
}