import iot1 from '../../../../assets/img/Iot1.png'
import iot2 from '../../../../assets/img/Iot2.png'

export default function IotInfo() {
    return (
        <section className='wrapper bg-white'>
            <div className='col-md-8 col-lg-8 mx-auto'>
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">What is IoT?</h3>
                            <p className="mb-5">
                            Internet of things(IoT) is the concept of embedding sensors or softwares in any device via the internet so it can exchange data with similar other devices, systems, networks and humans.
                            </p>
                            <p className="mb-5">
                            Simply put, IoT is when things or objects are connected to the internet and they share data among themselves. These things can be physical objects, devices, humans, machines, animals, etc.
                            </p>
                            <p className="mb-5">
                            The best example of IoT is the smart home concept. You can not only switch on all your lights, fans and appliances but also control the full range of functionality using just your phone. A smart fridge enables you to pre-order extra ice when you are out in a grocery store shopping for tonight's party. Switching on your bedroom A/C when you are on your way home is also only possible through IoT.
                            </p>
                            <p className="mb-5">
                            IoT has a wide range of applications including healthcare, elder care, transportation, agriculture, manufacturing, vehicular communication, energy management, environmental monitoring and even military application.
                            </p>               
                        </div>
                        <div className="col-lg-4 order-lg-2 position-relative">
                            <figure className="rounded mb-0"><img src={iot1} srcSet={iot1} alt="" /></figure>
                        </div>
                    </div>
                </div>
                <div className=" py-2 mx-4">
                    <h3 className="display-6 mb-4">How does IoT work?</h3>
                    <p className="mb-5">
                    All devices have hardware like sensors and are connected to an integrated platform. They collect data and share it via the cloud.
                    </p>
                    <p className='mb-5'>
                    The platform combines data from different devices and and applies analytics to share the most valuable information with users via applications built to address specific needs.
                    </p>
                    <p className='mb-5'>
                    Hence, an otherwise dumb object is now turned into digitally intelligent device which can communicate real-time data without any human involvement.
                    </p>
                    <p className="mb-5">
                    With the help of IoT backed meaningful insights provided on your mobile phone application you can monitor, for example, how much electricity each device uses, calculate spending on utilities in real time, track humidity and other air quality conditions in your space, etc.
                    </p>
                    <p className="mb-5">
                    IoT is basically combining the physical and digital universe to make the world around us more responsive and smarter.
                    </p>
                </div>
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-4 position-relative">
                            <figure className="rounded mb-0"><img src={iot2} srcSet={iot2} alt="" /></figure>
                        </div>
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">How InfyU uses IoT?</h3>
                            <p className="mb-5">
                            The sensors in Infyzer detects internal quality factors such as: taste, texture, nutrition, and the lack of biotic and abiotic contaminants in fruits and vegetables. External quality factors include appearance, uniformity, maturity and freshness of fruits. This data is then shared with our IoT backed applications that facilitate automated grading, shelf life prediction and source of procurement.
                            </p>
                            <p className="mb-5">
                            The concept of IoT was first discussed back in 1982, based on a Coca-Cola vending machine at Carnegie Mellon University becoming the first ARPANET-connected appliance, which was able to report its inventory and whether newly loaded drinks were cold or not.
                            </p>
                            <p className="mb-5">
                            After years of evolution, IoT now refers to billions of physical devices around the world that are now connected to the internet, all collecting and sharing data to enable the development of smart solutions for almost every problem in any sector.
                            </p>              
                        </div>
                        
                        {/*/column */}
                    </div>
                </div>

            </div>
        </section>
    );
}
