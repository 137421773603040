import portalab from "../../../../assets/img/Portalab.png";

export default function ProductDetails(){
    return(
        <div className="classic-view">
            <article className="post">
                <div className="post-content">
                    <section className="wrapper bg-light">
                        <div className=" mt-n4">
                            <div className="row gy-10 mt-0 align-items-center">
                                <div className="col-lg-4 order-lg-2 position-relative">
                                    <figure className="rounded mb-0"><img src={portalab} srcSet={portalab} alt="" /></figure>
                                </div>
                                <div className="col-lg-8">
                                    <h3 className="display-6 mb-4">Portalabs</h3>
                                    <p className="mb-5">
                                    Our PortaLabs service aims to offer easy and acccesible testing centers. Farmers, retailers or aggregators can easily approach these labs to get their product tested. At these labs we will help you test your consignment hasslefree and quickly without having to purchase our quality testing device.
                                    </p>
                                    <p className="mb-5">
                                    Within a few minutes a quality report as per your requirement will be generated for you at minimum costing.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </section>

                   
                    <p>
                    So, say goodbye to long queues and hefty transportation, reach out to your nearest PortaLabs and leave the rest to us. 
                    </p>
                    <p>
                    The standardized reports that we provide from PortaLabs can be used to negotiate and receive fair prices of the produce in the open market. Because of the reduced transportation time and saved wait time, the product is still fresh by the time it reaches markets. 
                    </p>
                    <p>
                    Quality reports provided are standardized as the testing is carried out using Infyzer. 
                    </p>
                    <p>
                    Do not want to buy or rent Infyzer, don't worry we have got you covered. Visit our PortaLabs and let us help you quality check your consigments hasslefree at affordable rates. 
                    </p>
                </div>
            </article>
        </div>
    )
}