import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { motion } from "framer-motion";

import BG from "../../../assets/img/photos/farmers.webp";
import farmer from "../../../assets/img/Farmer.png";

export default function Farmers() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                {/* <section className="wrapper bg-soft-primary"> */}
                <section
                    className='wrapper image-wrapper bg-image bg-overlay bg-overlay-400'
                    data-image-src={BG}
                >
                    <div className='container pt-16 pb-16 pt-md-16 pb-md-16 text-center'>
                        <div className='row'>
                            <div className='col-md-7 col-lg-6 col-xl-5 mx-auto'>
                                <h1 className='display-1 mb-3 text-white'>
                                    Farmers
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='wrapper bg-white'>
                    <div className='col-md-8 col-lg-8 mx-auto'>
                        <div className=' py-2 mx-4'>
                            <div className='row gy-10 mt-0 align-items-center'>
                                <div className='col-lg-8'>
                                    {/* <h3 className="display-6 mb-4">What is IoT?</h3> */}
                                    <p className='mb-5'>
                                        Around two million tonnes of pesticides
                                        are applied, yet 20 to 40 per cent of
                                        the global crop yields are lost to pests
                                        and diseases, as per the Food and
                                        Agriculture Organization (FAO) of the
                                        United Nations (UN).
                                    </p>
                                    <p className='mb-5'>
                                        Roughly one-third of the food produced
                                        in the world for human consumption every
                                        year gets lost or wasted. These
                                        statistics are never-ending and
                                        appalling, not just for farmers but for
                                        people and also from the food security
                                        perspective. Here is where technology
                                        and smart farming can play a critical
                                        role in helping farmers. In years to
                                        come, agritech will become an imperative
                                        tool helping farmers make informed
                                        decisions for different phases of
                                        farming.
                                    </p>
                                    <p className='mb-5'>
                                        IoT, nanotechnology and AI-driven
                                        devices can change the course of
                                        decision making, enhance profitability,
                                        help take corrective measures on time,
                                        reduce wastage, save cost and increase
                                        income.
                                    </p>
                                </div>
                                <div className='col-lg-4 position-relative'>
                                    <figure className='rounded mb-0'>
                                        <img
                                            src={farmer}
                                            srcSet={farmer}
                                            alt=''
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className=' py-2 mx-4'>
                            <p className='mb-5'>
                                Soil, weather, water availability and market
                                demand of a particular crop in a specific
                                location determine the type and amount of crop
                                production. Agritech and easy to use tech
                                devices can help analyse the economic and
                                productivity aspects pertaining to the
                                production, distribution and demand of different
                                crops. Thus, giving room to productivity and
                                sustainability.
                            </p>
                            <p className='mb-5'>
                                InfyU LABS is working towards making this a
                                reality. The products developed by InfyU LABS
                                are not only comprehensible but effective and
                                precise.
                            </p>
                            <p className='mb-5'>
                                They cover all the bases spanning from data
                                collection to analysis and can help in
                                harnessing collected data to make decisions
                                regarding the two important W’s— what to grow
                                and where to grow.
                            </p>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </motion.div>
    );
}
