import infyqr from '../../../../assets/img/InfyQR.png'

export default function ProductDetails() {
    return (
        <div className="classic-view">
            <article className="post">
                <div className="post-content">
                    <section className="wrapper bg-light">
                        <div className=" mt-n4">
                            <div className="row gy-10 mt-0 align-items-center">
                                <div className="col-lg-4 order-lg-2 position-relative">
                                    <figure className="rounded mb-0"><img src={infyqr} srcSet={infyqr} alt="" /></figure>
                                </div>
                                <div className="col-lg-8">
                                    <h3 className="display-6 mb-4">InfyQR</h3>
                                    <p className="mb-5">
                                    Why check quality multiple times when you can do it once and refer it again later. Yes, we have come up with InfyQR for seamless traceability. 
                                    </p>
                                    <p className="mb-5">
                                    Once a consignment is quality assessed using InfyU LABS device, a QR sticker will be embedded on the said consignment. The QR code can be scanned on an ad hoc basis to retrieve quality reports of the consignment. 
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </section>

                    <h2 className="mt-8">Traceability is the key</h2>
                    <p>
                    With InfyQR code we bring in traceability which is one of the most important factors in the agricultural world. As end customers are getting more and more aware of what they eat, traceability is something which all players in the cycle will have to look at. 
                    </p>
                    <p>
                    InfyQR will not only let the consumers review the product they are buying and establish traceability but also maintain a transparency and trust between the buyers and consumers.
                    </p>
                </div>
            </article>
        </div>
    )
}