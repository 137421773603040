import BG from '../../../assets/img/photos/about-us.webp';

export default function AboutUsHeader() {
    return (
        // <section className="wrapper bg-soft-primary">
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-500" data-image-src={BG}>
            <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-3 text-white">About Us</h1>
                        <p className="lead px-lg-5 px-xxl-8 text-white"><strong>"We are dedicated to the democratization of food testing with a mission to make chemical-free quality fruits accessible to everyone."</strong></p>
                    </div>
                </div>
            </div>
        </section>
    )
}