export default function AboutUsInfo() {
    return (
        <section className="wrapper bg-white">
            {/* <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center"> */}
                {/* <div className="row"> */}
                    <div className="col-md-8 col-lg-8 mx-auto">
                        {/* <h1 className="display-1 mb-3">About Us</h1> */}
                        <p className="lead px-lg-5 px-xxl-8 mt-6 px-3">
                        InfyU LABS is an Indian-based agritech company with IoT based products for quality assurance of fruits and vegetables that benefits every stakeholder in the food safety and supply chain. The innovative product developed at InfyU LABS helps in carrying out tests at scale and in a cost-effective manner. India records wastage of Rs 244 crore worth of food every day at different levels of the supply chain. We are working on various products to alleviate the problem of food insecurity and food wastage in India. The innovations developed by InfyU LABS have the potential to inspect fruits and vegetables digitally at the source with much less human dependency, errors, and wastage. The powerful deep learning algorithms incorporated in these products help businesses generate the most accurate results as per their requirements.
                        </p>
                        <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">The Problem</h2>
                        <p className="lead px-lg-5 px-xxl-8 px-3">
                        Food testing currently is an inaccessible, tedious and costly process as it requires technical staff such as lab technicians and expensive lab infrastructure. Additionally, results from conventional lab testing usually take a couple of days to weeks. Additional costs are incurred for transporting sample fruits and vegetables to laboratories. A chunk of fruits and vegetables are also wasted due to destructive testing in place. If a bad quality consignment of fruits or vegetables is accepted at the initial stage of the supply chain, it becomes unsellable by the time it reaches the consumer. We are working towards bridging this gap since 2019. 
                        </p>
                        <h2 className="px-lg-5 px-xxl-8 mt-10 px-3">Our Role</h2>
                        <p className="lead px-lg-5 px-xxl-8 px-3">
                        We are trying to build an International platform for Indian Fruit cultivators. We, at InfyU LABS, are working to develop cloud connected technology to check the quality of fruits and eliminate the fallouts of manual/ laboratory checks. We aim to reduce food wastage and improve Food Safety using technology.
                        </p>
                        <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">Our Solutions</h2>
                        <p className="lead px-lg-5 px-xxl-8 px-3">
                        We have diligently worked on creating a wide range of cost effective, user friendly products. These can help in carrying out tests in the absence of technical infrastructure and without sophisticated and expensive machines.
                        </p>
                        <p className="lead px-lg-5 px-xxl-8 px-3">
                        The algorithms used will give you the most accurate results instantly and easily. The test results will have a detailed report of the chemical composition of the tested fruit or vegetable. Such reports will help the user analyse the chemical composition of fruits and vegetables to make critical decisions at the time of accepting the consignment.
                        </p>
                        <p className="lead px-lg-5 px-xxl-8 px-3">
                        These easy to use products will cater to different levels of supply chains and help make food security a reality. Some of our products eliminate the need of transporting fruits and vegetables as the food testing can be completed right at the time of procurement further saving additional transportation charges.
                        </p>
                        
                    </div>
                {/* </div> */}
            {/* </div> */}
        </section>
    )
}