// import Bg from "../../../assets/img/photos/bg2.jpg";

export default function PreviousProjects() {
    return (
        <section className='wrapper bg-gray'>
            <div className='container py-14 pt-md-0 pb-md-16'>
                {/* <div className="row mt-md-n50p mb-14 mb-md-7">
                    <div className="col-xl-10 mx-auto">
                        <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400" data-image-src={Bg}>
                            <div className="card-body p-9 p-xl-11">
                                <div className="row align-items-center counter-wrapper gy-8 text-center text-white">
                                    <div className="col-6 col-lg-3">
                                        <h3 className="counter counter-lg text-white">7518</h3>
                                        <p>Completed Projects</p>
                                    </div>

                                    <div className="col-6 col-lg-3">
                                        <h3 className="counter counter-lg text-white">3472</h3>
                                        <p>Happy Customers</p>
                                    </div>

                                    <div className="col-6 col-lg-3">
                                        <h3 className="counter counter-lg text-white">2184</h3>
                                        <p>Expert Employees</p>
                                    </div>

                                    <div className="col-6 col-lg-3">
                                        <h3 className="counter counter-lg text-white">4523</h3>
                                        <p>Awards Won</p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div> */}

                <div className='row text-center'>
                    <div className='col-lg-9 col-xl-8 col-xxl-7 mx-auto'>
                        {/* <h2 className="fs-15 text-uppercase text-primary mb-3 mt-10">Case Studies</h2> */}
                        <h3 className='display-4 mb-6 mt-10'>
                            Check Out Some Of Our Awesome Ideas...
                        </h3>
                    </div>
                </div>

                <div className='position-relative'>
                    <div
                        className='shape bg-dot primary rellax w-17 h-20'
                        data-rellax-speed='1'
                        style={{ top: 0, left: "-1.7rem" }}
                    ></div>
                    <div
                        className='carousel owl-carousel gap-small blog grid-view'
                        data-margin='0'
                        data-dots='true'
                        data-autoplay='false'
                        data-autoplay-timeout='5000'
                        data-responsive='{"0":{"items": "1"}, "768":{"items": "2"}, "992":{"items": "2"}, "1200":{"items": "3"}}'
                    >
                        <div className='item'>
                            <div className='item-inner'>
                                <article>
                                    <div className='card'>
                                        {/* <figure className='card-img-top overlay overlay1 hover-scale'>
                                            <a href='/#'>
                                                {" "}
                                                <img
                                                    src='./assets/img/photos/b5.jpg'
                                                    alt=''
                                                />
                                            </a>
                                            <figcaption>
                                                <h5 className='from-top mb-0'>
                                                    Read More
                                                </h5>
                                            </figcaption>
                                        </figure> */}
                                        <div
                                            style={{ minHeight: "265px" }}
                                            className='card-body'
                                        >
                                            <div className='post-header'>
                                                <h2 className='post-title h3 mt-1 mb-3'>
                                                    SpectralBox
                                                </h2>
                                            </div>

                                            <div className='post-content'>
                                                <p>
                                                    Portable external quality
                                                    assessment box which works
                                                    on the principles of
                                                    Multispectral Imaging to
                                                    determine external
                                                    parameters such as SunBurn,
                                                    Bruising, Defects, Size, Color
                                                    etc.
                                                </p>
                                            </div>
                                            {/* <div style={{ minHeight: "265px" }} className="card-body">
                                            <div className="post-header">
                                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/infyzer">Infyzer</a></h2>
                                            </div>

                                            <div className="post-content">
                                                <p>Portable external quality assessment box which works on the principles of Multispectral
                                                    Imaging to determine external parameters such as SunBurn, Bruising, Defects, Size etc.</p>
                                            </div> */}
                                        </div>

                                        {/* <div className="card-footer">
                                            <ul className="post-meta d-flex mb-0">
                                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>29 Mar 2021</span></li>
                                                <li className="post-comments"><a href="/#"><i className="uil uil-file-alt fs-15"></i>Workspace</a></li>
                                            </ul>

                                        </div> */}
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className='item'>
                            <div className='item-inner'>
                                <article>
                                    <div className='card'>
                                        {/* <figure className='card-img-top overlay overlay1 hover-scale'>
                                            <a href='/#'>
                                                {" "}
                                                <img
                                                    src='./assets/img/photos/b4.jpg'
                                                    alt=''
                                                />
                                            </a>
                                            <figcaption>
                                                <h5 className='from-top mb-0'>
                                                    Read More
                                                </h5>
                                            </figcaption>
                                        </figure> */}
                                        <div
                                            style={{ minHeight: "265px" }}
                                            className='card-body'
                                        >
                                            <div className='post-header'>
                                                <h2 className='post-title h3 mt-1 mb-3'>
                                                    InfyU PortaLabs
                                                </h2>
                                            </div>

                                            <div className='post-content'>
                                                <p>
                                                    Providing quality grading
                                                    services directly to farmers
                                                    where farmers can use graded
                                                    report of their consignment
                                                    for negotiating with traders.
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="card-footer">
                                            <ul className="post-meta d-flex mb-0">
                                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>14 Apr 2021</span></li>
                                                <li className="post-comments"><a href="/#"><i className="uil uil-file-alt fs-15"></i>Coding</a></li>
                                            </ul>

                                        </div> */}
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className='item'>
                            <div className='item-inner'>
                                <article>
                                    <div className='card'>
                                        {/* <figure className='card-img-top overlay overlay1 hover-scale'>
                                            <a href='/#'>
                                                {" "}
                                                <img
                                                    src='./assets/img/photos/b6.jpg'
                                                    alt=''
                                                />
                                            </a>
                                            <figcaption>
                                                <h5 className='from-top mb-0'>
                                                    Read More
                                                </h5>
                                            </figcaption>
                                        </figure> */}
                                        <div
                                            style={{ minHeight: "265px" }}
                                            className='card-body'
                                        >
                                            <div className='post-header'>
                                                <h2 className='post-title h3 mt-1 mb-3'>
                                                    InfyQR
                                                </h2>
                                            </div>

                                            <div className='post-content'>
                                                <p>
                                                    Integrating internal quality
                                                    based QR stickers which will
                                                    provide consumers an option
                                                    to purchase fruits based on
                                                    their internal quality and
                                                    price accordingly.
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="card-footer">
                                            <ul className="post-meta d-flex mb-0">
                                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>26 Feb 2021</span></li>
                                                <li className="post-comments"><a href="/#"><i className="uil uil-file-alt fs-15"></i>Meeting</a></li>
                                            </ul>

                                        </div> */}
                                    </div>
                                </article>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}
