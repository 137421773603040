export default function NewsPage({page}){
    if(page===1){
        return(
            <>
                <article className='item post col-md-6'>
                    <div
                        // style={{ height: "350px" }}
                        className='card'
                    >
                        <figure
                            style={{ height: "225px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='https://lnkd.in/gqrriZ_b' target="_blank" rel="noopener noreferrer">
    
                                <img
                                    src='https://images.yourstory.com/cs/2/f02aced0d86311e98e0865c1f0fe59a2/quality-control-1635838722783.png?fm=auto&ar=2:1&mode=crop&crop=faces&w=720'
                                    alt='Digitising quality control in farm to fork ecosystem'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="https://lnkd.in/gqrriZ_b" target="_blank" rel="noopener noreferrer">Digitising quality control in farm to fork ecosystem</a></h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>India ranks second in fruits and vegetable production in the world. However, the country lags in the export of quality produce. Emerging technologies such as artificial intelligence (AI) can improve quality control, helping farmers get a better price for their produce.</p>
                        </div>
                    </div>
                </article>
    
                <article className='item post col-md-6'>
                    <div
                        className='card'
                    >
                        <figure
                            style={{ height: "225px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='https://lnkd.in/eWvDtWaW' target="_blank" rel="noopener noreferrer">
    
                                <img
                                    src='https://images.unsplash.com/photo-1590682680695-43b964a3ae17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
                                    alt='How Standardizing Quality Control Can Improve Trust in eNAM'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="https://lnkd.in/eWvDtWaW" target="_blank" rel="noopener noreferrer">How Standardizing Quality Control Can Improve Trust in eNAM</a></h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                            In India, post-harvest losses of vegetables and fruits amount to nearly 40%, which adversely affects the economics of farming. A large chunk of this wastage can be traced back to inefficient quality control and a substandard supply chain. Agritech solutions in the market are promising faster, standardized, and transparent quality control for farmers and consumers.
                            </p>
                        </div>
                    </div>
                </article>
    
                <article className='item post col-md-6'>
                    <div
                        className='card'
                    >
                        <figure
                            style={{ height: "225px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='https://lnkd.in/gqQCZiM5' target="_blank" rel="noopener noreferrer">
    
                                <img
                                    src='https://www.forbesindia.com/media/images/2021/Nov/img_171973_farmerscelebrate-1236655758_bg.jpg'
                                    alt='Farm laws repeal: What lies ahead'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="https://lnkd.in/gqQCZiM5" target="_blank" rel="noopener noreferrer">Farm laws repeal: What lies <br /> ahead</a></h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Farmers victorious but to continue protests until formal repeal; agritech players look at contract losses, higher prices</p>
                        </div>
                    </div>
                </article>
    
                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://www.thehindubusinessline.com/economy/budget/agri-industry-reacts-to-fm-sitharamans-budget-speech/article64962665.ece' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://www.thehindubusinessline.com/incoming/p3070i/article64962660.ece/alternates/FREE_660/NDBL-23-1-2-22.jpg'
                                    alt='Agri industry reacts to FM Sitharaman’s Budget speech'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="https://www.thehindubusinessline.com/economy/budget/agri-industry-reacts-to-fm-sitharamans-budget-speech/article64962665.ece" target="_blank" rel="noopener noreferrer">Agri industry reacts to FM Sitharaman’s Budget speech</a></h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Atul Chaturvedi, President of the Solvent Extractors’ Association (SEA) of India, said SEA of India had been constantly representing to the Government that India’s edible oil imports are skyrocketing and seriously compromising edible oil security of the nation needing urgent policy intervention.</p>
                        </div>
                    </div>
                </article>
            </>
        )
    }
    else if(page===2){
        return(
            <>
                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://www.thehindu.com/news/cities/chennai/food-safety-officials-seize-over-350-kg-of-adulterated-vegetables-in-koyambedu/article38352440.ece?utm_campaign=socialflow' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://th.thgim.com/news/cities/chennai/vgg3d3/article38352437.ece/ALTERNATES/FREE_660/31JANTH--Koyambedu'
                                    alt='Food safety officials seize over 350 kg of adulterated vegetables in Koyambedu'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://www.thehindu.com/news/cities/chennai/food-safety-officials-seize-over-350-kg-of-adulterated-vegetables-in-koyambedu/article38352440.ece?utm_campaign=socialflow" target="_blank" rel="noopener noreferrer">
                                    Food safety officials seize over 350 kg of adulterated vegetables in Koyambedu
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Green peas, butter beans and papad packets were found to contain adulterated colouring agents, officials said</p>
                        </div>
                    </div>
                </article>
                
                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://www.jagran.com/business/budget-budget-2022-reactions-drones-and-startups-can-prove-to-be-a-booster-dose-for-the-economy-22430402.html' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://www.jagranimages.com/images/newimg/01022022/01_02_2022-drone__22430402.jpg'
                                    alt='budget 2022 reactions'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://www.jagran.com/business/budget-budget-2022-reactions-drones-and-startups-can-prove-to-be-a-booster-dose-for-the-economy-22430402.html" target="_blank" rel="noopener noreferrer">
                                    Budget 2022 Reactions: ड्रोन और स्टार्टअप साबित हो सकते हैं इकोनॉमी के लिए बूस्टर डोज
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Budget 2022 Reactions 5जी रोलआउट के लिए स्पेक्ट्रम नीलामी के साथ शुरू करने का विचार सरकार द्वारा एक बेहद स्वागत योग्य कदम है।ऐसा करने से संचार क्षेत्र को अत्यधिक लाभ होगा और व्यवसायों का तेजी से विकास होगा|टैक्स प्रोत्साहन से 2023 तक स्टार्टअप्स को भी फायदा होगा।</p>
                        </div>
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://www.thehindubusinessline.com/economy/agri-business/agtech-is-here-not-to-replace-farmers-but-make-cultivation-easier/article37966594.ece' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://www.thehindubusinessline.com/economy/agri-business/xnod07/article37678594.ece/alternates/FREE_615/BL26agtech'
                                    alt='agritech'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://www.thehindubusinessline.com/economy/agri-business/agtech-is-here-not-to-replace-farmers-but-make-cultivation-easier/article37966594.ece" target="_blank" rel="noopener noreferrer">
                                    Agtech is here not to replace farmers, but make cultivation easier
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                                To make the best of agritech, it is important for agricultural ecosystem to know that technology is a friend and not a foe
                            </p>
                        </div>
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://wap-business--standard-com.cdn.ampproject.org/c/s/wap.business-standard.com/article-amp/companies/despite-farm-laws-repeal-agritech-investments-may-rise-to-700-mn-in-fy22-121112000795_1.html' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://img.jagranjosh.com/imported/images/E/GK/Farm-Laws-2020-Indian-farm-reforms-agriculture-bills-farm-bills-2020.jpg'
                                    alt='agritech investments may rise to $700 mn in FY22'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://wap-business--standard-com.cdn.ampproject.org/c/s/wap.business-standard.com/article-amp/companies/despite-farm-laws-repeal-agritech-investments-may-rise-to-700-mn-in-fy22-121112000795_1.html" target="_blank" rel="noopener noreferrer">
                                    Despite farm laws' repeal, agritech investments may rise to $700 mn in FY22
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                                The announcement of repealing of the three contentious farm laws by Prime Minister Narendra Modi would not have any significant impact on the funding of agritech startups, according to venture capital (VC) firms who operate in the sector.
                            </p>
                        </div>
                    </div>
                </article>
            </>
        )
    }

    else if(page===3){
        return(
            <>
                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://www.fortuneindia.com/macro/not-all-are-happy-with-scrapping-of-3-farm-laws/106228' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://images.assettype.com/fortuneindia%2F2021-11%2F3d1e66ce-2b45-4b99-b6a8-29e54cebfa84%2Ffarmers_pic.jpg?w=1250&q=60'
                                    alt='farm laws'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://www.fortuneindia.com/macro/not-all-are-happy-with-scrapping-of-3-farm-laws/106228" target="_blank" rel="noopener noreferrer">
                                    Not all are happy with scrapping of 3 farm laws
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                                Experts, stakeholders divided on government’s move; implementation not up to the mark, say some, could have opened new channels of trading for farmers, say others.
                            </p>
                        </div>
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://timesofindia.indiatimes.com/blogs/voices/future-of-food-production-how-agritech-can-solve-the-great-indian-agrarian-crisis/' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://images.yourstory.com/cs/2/f02aced0d86311e98e0865c1f0fe59a2/quality-control-1635838722783.png?fm=auto&ar=2:1&mode=crop&crop=faces&w=720'
                                    alt='fuyure of food production'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://timesofindia.indiatimes.com/blogs/voices/future-of-food-production-how-agritech-can-solve-the-great-indian-agrarian-crisis/" target="_blank" rel="noopener noreferrer">
                                    Future of food production: How agritech can solve the great Indian agrarian crisis?
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                                Contributing to about 15% to India’s GDP, the agricultural sector sustains almost half of the country’s population. With more than 265 Mn people engaged in farming, India is the second largest producer of fruits and vegetables. However, India still has a long way to go in terms of exporting the ‘produce’.
                            </p>
                        </div>
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://hindi.newsbytesapp.com/news/exclusive/infyu-labs-co-founders-share-how-they-are-changing-agritech-with-ai-and-machine-modeling/story' target="_blank" rel="noopener noreferrer">
                                <img
                                    src='https://i.cdn.newsbytesapp.com/hn/images/l96320211023100304.jpeg'
                                    alt='farmers ai'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://hindi.newsbytesapp.com/news/exclusive/infyu-labs-co-founders-share-how-they-are-changing-agritech-with-ai-and-machine-modeling/story" target="_blank" rel="noopener noreferrer">
                                    NewsBytesExclusive: किसानों को AI से जोड़ने की कोशिश में लगे अमित और अंकित
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                                भारत में सबसे ज्यादा आबादी कृषि से जुड़ी है और यहां फसलों के अलावा फल और सब्जियों का खूब उत्पादन होता है लेकिन निर्यात की बात करें तो भारत मजबूत दावेदारी पेश नहीं करता।
                                फसलों की गुणवत्ता इसके लिए जिम्मेदार है और किसानों को वैज्ञानिक तरीके आजमाने की जरूरत है।
                                किसानों के लिए क्वॉलिटी कंट्रोल कितना जरूरी और फायदेमंद है, यह समझने के लिए न्यूजबाइट्स ने InfyU लैब्स के को-फाउंडर्स अमित श्रीवास्तव और अंकित चौहान से बात की।
                            </p>
                        </div>
                    </div>
                </article>

                <article className='item post col-md-6'>
                    <div className='card'>
                        <figure style={{ height: "225px" }} className='card-img-top overlay overlay1 hover-scale'>
                            <a href='https://inc42-com.cdn.ampproject.org/c/s/inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-october-2021/amp/' target="_blank" rel="noopener noreferrer">
                                <img
                                    src="https://inc42-com.cdn.ampproject.org/i/s/inc42.com/wp-content/uploads/2021/11/Feature_Social_october-30-startups_1360X1020-690x518.jpg"
                                    alt='tech startups for 2022'
                                    loading='lazy'
                                />
                            </a>
                            <figcaption>
                                <h4 className='from-top mb-0'>
                                    Read More
                                </h4>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <h2 className="post-title h3 mt-1 mb-3" style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 2, WebkitBoxOrient: "vertical"}}>
                                <a className="link-dark" href="https://inc42-com.cdn.ampproject.org/c/s/inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-october-2021/amp/" target="_blank" rel="noopener noreferrer">
                                    30 Startups To Watch: The Startups That Caught Our Eye In October 2021
                                </a>
                            </h2>
                            <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                            The world has taken a quantum leap towards digital. And digital has changed everything. From remote working and virtual networking to mobile gaming and OTT-first entertainment, from foodtech, edtech and telemedicine to fintech and digital asset classes, from a demure Web 2.0 to a blockchain-driven metaverse — the changes have been unprecedented, a morbid thanks to the Covid-19 pandemic.    
                            </p>
                        </div>
                    </div>
                </article>
            </>
        )
    }
    
}