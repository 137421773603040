import BG from "../../../assets/img/photos/video.webp";

export default function VideoHeader() {

    return (
        // <section className="wrapper bg-soft-primary">
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white" data-image-src={BG}>
            <div className="container pt-16 pb-16 pt-md-16 pb-md-16 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className='text-white display-1'>Videos</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}