import { useState } from 'react';
import VideosPage from './VideosPage';

export default function VideosContainer() {
    const [pageNo, setPageNo] = useState(1);

    return (
        <>
            <section className='wrapper bg-white'>

                <div className="col-md-8 col-lg-8 mx-auto">
                    <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">Showcasing Our Range of Products</h2>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    Our products are designed and developed in such a way that they do not require any learning curve or technical skills. Our products and services even eliminate the need for conducting tests in lab facilities. 
                    </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    But we believe in transparency and accessibility! To ensure that our users and other stakeholders are aware of the various facets of our products such as their operation, before use, after use pointers to be kept in mind and much more InfyU LABS showcases its products on this page.                </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    Aversion to technology can be pegged as one of the greatest hazards for the field of agriculture. The videos aim to explore the marriage of technology and agriculture and its benefits. The demos help you understand the nuances and serve as visual guidelines to help you navigate the working of the devices. 
                    </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    Here are some of the demo videos for the viewers.
                    </p>
                </div>

                <div className='container pb-14 pb-md-16 pt-5'>
                    <div className='row'>
                        <div className='col-lg-9 mx-auto'>
                            <div className='blog grid grid-view'>
                                <div className='row isotope gx-md-8 gy-8 mb-8'>
                                    <VideosPage page={pageNo}/>
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <button className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${pageNo===1? "btn-outline-primary" : ""}`}
                                        onClick={() => {setPageNo(1)}}>
                                    1
                                </button>
                                <button className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${pageNo===2? "btn-outline-primary" : ""}`}
                                        onClick={() => {setPageNo(2)}}>
                                    2
                                </button>
                                <button className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${pageNo===3? "btn-outline-primary" : ""}`}
                                        onClick={() => {setPageNo(3)}}>
                                    3
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    );
}
