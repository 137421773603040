

// import Apple from '../../../assets/img/apple.png';
// import Mango from '../../../assets/img/mango.png';
// import Guava from '../../../assets/img/guava.png';
// import Papaya from '../../../assets/img/papaya.png';
// import Orange from '../../../assets/img/orange.png';
// import Watermelon from '../../../assets/img/watermelon.png';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';
import LIST_ITEMS from '../../../constant/SEED_ITEMS';



// const IMG_SRC = {
//     'Apple': Apple,
//     'Mango': Mango,
//     'Guava': Guava,
//     'Papaya': Papaya,
//     'Orange': Orange,
//     'Watermelon': Watermelon
// }



export default function DemoList({ setItems, isItems, pageStack }) {

    const [LISTS, setLists] = useState({});
    const isMounted = useRef(false);

    useEffect(() => {
        const LIST_ITEM = {};
        
        for (const p in LIST_ITEMS) {
            LIST_ITEM[p] = LIST_ITEMS[p][Math.round(Math.random() * (LIST_ITEMS[p].length - 1))];
            for (const q in LIST_ITEM[p]) {
                if (q !== "Images" && q !== "Defect" && q !== "Color") {
                    if ("properties" in LIST_ITEM[p]) {

                    } else {
                        LIST_ITEM[p]["properties"] = {};
                    }

                    LIST_ITEM[p]["properties"][q] = LIST_ITEM[p][q];
                }
            }
        }
        // console.log(LIST_ITEM);
        setLists(LIST_ITEM);
        console.log(LIST_ITEM);
    }, []);

    useEffect(() => {
        if (isMounted.current && isItems.isVis) {
            $('.listItemsContainer').hide();
            pageStack.current.push('.imageContainer');
            $('.demoImage').attr('src', isItems.item["Images"]);
            $('.imageContainer').css({
                'height': '100%',
                'display': 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
                'align-items': 'center',
            });
            $('.demoImage').css({
                // 'margin': '50% 0 0 0',
                'min-width': '230px',
                'width': '55%'
            });
            $('.demoContainer').css('cursor', 'default');
            // $('.demoContainer').css('cursor', 'none');
        } else {
            isMounted.current = true;
        }
    }, [isItems, setItems])

    const clickHander = (n) => {

        setItems({ item: n, isVis: true });

    }
    return (
        <div className="listItemsContainer cardContainer" style={{ width: "100%" }}>
            <h1 style={{width: "100%", textAlign: "center"}}>Select a fruit</h1>
            {Object.keys(LISTS).map(item => {
                return (<div key={item} className="cardy demoListLi" onClick={() => clickHander({ ...LISTS[item], name: item })}>
                    <img alt="fgg" className="cardy-image" src={LISTS[item]["Images"]} loading="lazy" />
                    <h5 style={{ fontSize: '.82rem' }} className="cardy-text">{item}</h5>
                </div>);
            })}
        </div>
    )


}

