import "./assets/css/plugins.css";
import "./assets/css/style.css";

import "./assets/css/colors/purple.css";

import Blog from "./components/containers/Blog/Blog";
import BlogPost from "./components/containers/BlogPost/BlogPost";
import BlogPost2 from "./components/containers/BlogPost2/BlogPost2";
import BlogPost3 from "./components/containers/BlogPost3/BlogPost3";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
} from "react-router-dom";
import Contact from "./components/containers/Contact/Contact";
import Homepage from "./components/containers/Homepage/Homepage";
import Product from "./components/containers/Product/Product";
import Products from "./components/containers/Products/Products";
import AboutUs from "./components/containers/AboutUs/AboutUs";
// import Technology from './components/containers/Technology/Technology';
import Spectroscopy from "./components/containers/Technology/Spectroscopy/Spectroscopy";
import Videos from "./components/containers/Videos/Videos";

import Iot from "./components/containers/Technology/IOT/Iot";
import ColudComputing from "./components/containers/Technology/Cloud Computing/ColudComputing";
import BlogPost4 from "./components/containers/BlogPost4/BlogPost4";
import Team from "./components/containers/Our Team/Team";
import Solutions from "./components/containers/Solutions/Solutions";
import Retail from "./components/containers/Solutions/Retail";
import Aggregators from "./components/containers/Solutions/Aggregators";
import Farmers from "./components/containers/Solutions/Farmers";
import News from "./components/containers/News/News";
import Careers from "./components/containers/Careers/careers";
import InfyQR from "./components/containers/Product/infyQR/InfyQR";
import Portalabs from "./components/containers/Product/portaLabs/Portalabs";
import PrivacyPolicy from "./components/containers/Footer content/PrivacyPolicy";
import Terms from "./components/containers/Footer content/Terms";
import Credits from "./components/containers/Footer content/Credits";

import { AnimatePresence } from "framer-motion";

function App() {
    //let location = useLocation();

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <AnimatePresence exitBeforeEnter>
                <Switch>
                    <Route path='/about-us'>{AboutUs()}</Route>
                    <Route path='/blogs'>{Blog()}</Route>
                    <Route path='/blog1'>{BlogPost()}</Route>
                    <Route path='/blog2'>{BlogPost2()}</Route>
                    <Route path='/blog3'>{BlogPost3()}</Route>
                    <Route path='/blog4'>{BlogPost4()}</Route>

                    <Route path='/products'>{Products()}</Route>
                    <Route path='/infyzer'>{Product()}</Route>
                    <Route path='/infyQR'>{InfyQR()}</Route>
                    <Route path='/portalabs'>{Portalabs()}</Route>
                    <Route path='/contact'>{Contact()}</Route>

                    <Route path='/spectroscopy'>{Spectroscopy()}</Route>
                    <Route path='/IoT'>{Iot()}</Route>
                    <Route path='/cloudComputing'>{ColudComputing()}</Route>

                    <Route path='/video'>{Videos()}</Route>
                    <Route path='/solutions'>{Solutions()}</Route>
                    <Route path='/retailers'>{Retail()}</Route>
                    <Route path='/aggregators'>{Aggregators()}</Route>
                    <Route path='/farmers'>{Farmers()}</Route>
                    <Route path='/team'>{Team()}</Route>
                    <Route path='/news'>{News()}</Route>
                    <Route path='/careers'>{Careers()}</Route>
                    <Route path='/credits'>{Credits()}</Route>

                    <Route path='/terms-of-use'>{Terms()}</Route>
                    <Route path='/privacy-policy'>{PrivacyPolicy()}</Route>

                    <Route path='/'>{Homepage()}</Route>
                </Switch>
            </AnimatePresence>
        </Router>
    );
}

export default App;
