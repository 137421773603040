import BG from "../../../../assets/img/photos/Products-3.png";

export default function Banner() {
    return(
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" data-image-src={BG}>
            <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                <div className="row">
                    <div className="col-md-10 col-xl-8 mx-auto">
                        <div className="post-header">
                            <h1 className="display-1 mb-4 text-white">Portalabs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}