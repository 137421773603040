import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Details() {
    const [details, setDetails] = useState({});
    const [message, setMessage] = useState("");
    const contactRef = useRef(null);
    const scrollDown = () => {
        window.scrollTo({
            top: contactRef.current.offsetTop,
            behavior: 'smooth',
        });
    };
    setTimeout(() => {
        const pathname = window.location.href
        if (pathname.includes("#contactForm")) {
            scrollDown();
        }
    }, 1000);


    const url = process.env.REACT_APP_API_URL;
    const captchKey = process.env.REACT_APP_RECAPTCHA_KEY;

    const recaptcharef = useRef();

    const handleChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        setDetails((values) => ({ ...values, [name]: val }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const captchaToken = await recaptcharef.current.executeAsync();
        recaptcharef.current.reset();
        try {
            let res = fetch(`${url}/contactUs`, {
                method: "POST",
                body: JSON.stringify({
                    details,
                    captchaToken
                })
            })
            // console.log(await res);
            if ((await res).ok) {
                setDetails({});
                setMessage("Form submitted successfully...");
                // console.log("success");
            }
            else {
                console.log("some problem");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section className='wrapper bg-light angled upper-end'>
            <div className='container pb-11'>
                <div className='row mb-14 mb-md-16'>
                    <div className='col-xl-10 mx-auto mt-n19'>
                        <div className='card'>
                            <div className='row gx-0'>
                                <div className='col-lg-6 align-self-stretch'>
                                    <div className='map map-full rounded-top rounded-lg-start'>
                                        <iframe
                                            title='maps'
                                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117431.50812592347!2d72.56314906502614!3d23.106808297222702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2b60cc0fee53%3A0x24da71ec7eb7515!2sIIT%20Gandhinagar%20-%20Academic%20Block%209!5e0!3m2!1sen!2sin!4v1586980683723!5m2!1sen!2sin&ie=UTF8&iwloc=&output=embed'
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                border: 0,
                                            }}
                                            allowFullScreen
                                            loading='lazy'
                                        />
                                    </div>
                                    {/* /.map */}
                                </div>
                                {/*/column */}
                                <div className='col-lg-6'>
                                    <div className='p-10 p-md-11 p-lg-14'>
                                        <div className='d-flex flex-row'>
                                            <div>
                                                <div className='icon text-primary fs-28 me-4 mt-n1'>
                                                    {" "}
                                                    <i className='uil uil-location-pin-alt' />{" "}
                                                </div>
                                            </div>
                                            <div className='align-self-start justify-content-start'>
                                                <h5 className='mb-1'>
                                                    Address
                                                </h5>
                                                <address>
                                                    {" "}
                                                    InfyU LABS, IIEC, Block 9,
                                                    IIT Gandhinagar Palaj
                                                    Campus, Gandhinagar{" "}
                                                    <br className='d-none d-md-block' />{" "}
                                                    Gujarat, India, 382355.
                                                </address>
                                            </div>
                                        </div>
                                        {/*/div */}
                                        <div className='d-flex flex-row'>
                                            <div>
                                                <div className='icon text-primary fs-28 me-4 mt-n1'>
                                                    {" "}
                                                    <i className='uil uil-phone-volume' />{" "}
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Phone</h5>
                                                <a href="tel:+91-6397645838" className='link-body'>+91-6397645838</a>
                                            </div>
                                        </div>
                                        {/*/div */}
                                        <div className='d-flex flex-row'>
                                            <div>
                                                <div className='icon text-primary fs-28 me-4 mt-n1'>
                                                    {" "}
                                                    <i className='uil uil-envelope' />{" "}
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>E-mail</h5>
                                                <p className='mb-0'>
                                                    <a
                                                        href='mailto:sandbox@email.com'
                                                        className='link-body'
                                                    >
                                                        {" "}
                                                        hello@infyulabs.com
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        {/*/div */}
                                    </div>
                                    {/*/div */}
                                </div>
                                {/*/column */}
                            </div>
                            {/*/.row */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /column */}
                </div>
                {/* /.row */}
                {/* <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                        <h2 className="display-4 mb-3 text-center">Drop Us a Line</h2>
                    </div>
                </div> */}
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
                        <h2 className='display-4 mb-3 text-center'>
                            Contact Us
                        </h2>
                        <p className='text-center lead mb-10'>
                            We would love to hear from you. Here's how you can
                            reach us…
                        </p>
                        <h2 className=' mb-3 '>Talk to Sales</h2>
                        <p className='mb-0'>
                            Interested in our products? Pick up the phone and
                            connect with our sales team to know more.
                        </p>
                        <p className='mb-10 lead'>
                            <strong><a href="tel:+91-6397645838">+91-6397645838</a></strong>
                        </p>
                        <h2 className=' mb-3 '>Contact Customer Support</h2>
                        <p className='mb-10'>
                            Even though our devices are easy to use, we
                            understand the need for constant guidance and help.
                            Hence, we are here for you. Connect with us on{" "}
                            <strong><a href="tel:+91-6397645838">+91-6397645838</a></strong>.
                        </p>
                        <h2 className=' mb-3 '>Drop Us a Line</h2>
                        <p className=' mb-10'>
                            If you have any queries related to our products or
                            any other aspect pertaining to our business please
                            fill out the form below. Don't worry, we won't spam
                            you with emails, this form only helps us understand
                            your requirements thoroughly and aids us in
                            responding to them diligently.{" "}
                        </p>

                        <form
                            className='needs-validation'
                            // className='contact-form needs-validation'
                            noValidate
                            onSubmit={e => handleSubmit(e)}

                        >
                            <ReCAPTCHA
                                ref={recaptcharef}
                                sitekey={captchKey}
                                size='invisible'
                            />
                            {/* <form className="contact-form needs-validation" method="post" action="./assets/php/contact.php" noValidate> */}
                            <div className='messages' />
                            <div className='row gx-4' ref={contactRef}>
                                <div className='col-md-6'>
                                    <div className='form-floating mb-4'>
                                        <input
                                            value={details.name || ""}
                                            onChange={handleChange}
                                            id='form_name'
                                            type='text'
                                            name='name'
                                            className='form-control'
                                            placeholder='Jane'
                                            required
                                        />
                                        <label htmlFor='form_name'>
                                            First Name *
                                        </label>
                                        <div className='valid-feedback'>
                                            {" "}
                                            Looks good!{" "}
                                        </div>
                                        <div className='invalid-feedback'>
                                            {" "}
                                            Please enter your first name.{" "}
                                        </div>
                                    </div>
                                </div>
                                {/* /column */}
                                <div className='col-md-6'>
                                    <div className='form-floating mb-4'>
                                        <input
                                            value={details.surname || ""}
                                            onChange={handleChange}
                                            id='form_lastname'
                                            type='text'
                                            name='surname'
                                            className='form-control'
                                            placeholder='Doe'
                                            required
                                        />
                                        <label htmlFor='form_lastname'>
                                            Last Name *
                                        </label>
                                        <div className='valid-feedback'>
                                            {" "}
                                            Looks good!{" "}
                                        </div>
                                        <div className='invalid-feedback'>
                                            {" "}
                                            Please enter your last name.{" "}
                                        </div>
                                    </div>
                                </div>
                                {/* /column */}
                                <div className='col-md-6'>
                                    <div className='form-floating mb-4'>
                                        <input
                                            value={details.email || ""}
                                            onChange={handleChange}
                                            id='form_email'
                                            type='email'
                                            name='email'
                                            className='form-control'
                                            placeholder='jane.doe@example.com'
                                            required
                                        />
                                        <label htmlFor='form_email'>
                                            Email *
                                        </label>
                                        <div className='valid-feedback'>
                                            {" "}
                                            Looks good!{" "}
                                        </div>
                                        <div className='invalid-feedback'>
                                            {" "}
                                            Please provide a valid email
                                            address.{" "}
                                        </div>
                                    </div>
                                </div>
                                {/* /column */}
                                <div className='col-md-6'>
                                    <div className='form-select-wrapper'>
                                        <select
                                            name='department'
                                            value={details.department || ""}
                                            onChange={handleChange}
                                            className='form-select'
                                            id='form-select'
                                            required
                                        >
                                            <option disabled value={""}>
                                                Select a department
                                            </option>
                                            {/* <option selected disabled value>Select a department</option> */}
                                            <option value={"Sales"}>
                                                Sales
                                            </option>
                                            <option value={"Marketing"}>
                                                Marketing
                                            </option>
                                            <option value={"Customer Support"}>
                                                Customer Support
                                            </option>
                                        </select>
                                        <div className='valid-feedback'>
                                            {" "}
                                            Looks good!{" "}
                                        </div>
                                        <div className='invalid-feedback'>
                                            {" "}
                                            Please select a department.{" "}
                                        </div>
                                    </div>
                                </div>
                                {/* /column */}
                                <div className='col-12'>
                                    <div className='form-floating mb-4'>
                                        <textarea
                                            value={details.message || ""}
                                            onChange={handleChange}
                                            id='form_message'
                                            name='message'
                                            className='form-control'
                                            placeholder='Your message'
                                            style={{ height: "150px" }}
                                            required
                                        />
                                        <label htmlFor='form_message'>
                                            Message *
                                        </label>
                                        <div className='valid-feedback'>
                                            {" "}
                                            Looks good!{" "}
                                        </div>
                                        <div className='invalid-feedback'>
                                            {" "}
                                            Please enter your messsage.{" "}
                                        </div>
                                    </div>
                                </div>
                                {/* /column */}
                                <p className='text-center mb-2 text-primary'>{message}</p>
                                <div className='col-12 text-center'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary rounded-pill mb-3'

                                    >
                                        Send Message
                                    </button>
                                    {/* <input onChange={() => { }} type="submit" className="btn btn-primary rounded-pill btn-send mb-3" defaultValue="Send message" /> */}
                                    <p className='text-muted'>
                                        <strong>*</strong> These fields are
                                        required.
                                    </p>
                                </div>
                                {/* /column */}
                            </div>
                            {/* /.row */}
                        </form>
                        {/* /form */}
                    </div>
                    {/* /column */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    );
}
