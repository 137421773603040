import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import IotHeader from "./IotHeader";
import IotInfo from "./IotInfo";
import { motion } from "framer-motion";

export default function Iot() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                <IotHeader />
                <IotInfo />
                <Footer />
            </div>
        </motion.div>
    );
}
