


export default function Footer() {
    return (
        <>
            <footer className="bg-dark text-inverse">
                <div className="container py-13 py-md-15">
                    <div className="row gy-6 gy-lg-0">
                        <div className="col-md">
                            <div className="widget">
                                <img className="mb-4" src="../../../assets/img/logo-light.png" srcSet="../../../assets/img/logo-light@2x.png 2x" alt="" />
                                <p className="mb-4">© 2022 InfyU LABS <br className="d-none d-lg-block" />All rights reserved.</p>
                                <nav className="nav social social-white">
                                    <a href="https://www.linkedin.com/company/infyu-labs/" target="_blank" rel="noopener noreferrer"><i className="uil uil-linkedin" /></a>
                                    <a href="https://www.facebook.com/InfyULabs" target="_blank" rel="noopener noreferrer"><i className="uil uil-facebook-f" /></a>
                                    <a href="https://instagram.com/infyulabs" target="_blank" rel="noopener noreferrer"><i className="uil uil-instagram" /></a>
                                    <a href="https://www.youtube.com/channel/UCGJUiewiaC3kOy3b6Y-IQEg" target="_blank" rel="noopener noreferrer"><i className='uil uil-youtube'></i></a>
                                </nav>
                                {/* /.social */}
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                        <div className="col-md">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Get in Touch</h4>
                                <address className="pe-xl-15 pe-xxl-17">InfyU LABS, IIEC, Block 9, IIT Gandhinagar
                                    Palaj Campus, Gandhinagar, Gujarat, India, 382355.</address>
                                <a href="mailto:hello@infyulabs.com">hello@infyulabs.com</a><br />
                                <a href="tel:+91-6397645838">+91-6397645838</a>
                                 
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                        <div className="col-md">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Learn More</h4>
                                <ul className="list-unstyled  mb-0">
                                    <li><a href="/about-us">About Us</a></li>
                                    {/* <li><a href="/#">Our Story</a></li> */}
                                    <li><a href="/products">Projects</a></li>
                                    <li><a href="/careers">Careers</a></li>
                                    <li><a href="/terms-of-use">Terms of Use</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href='/credits'>Credits</a></li>
                                </ul>
                            </div>
                            {/* /.widget */}
                        </div>
                        {/* /column */}
                        {/* <div className="col-md-12 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
                                <p className="mb-5">Subscribe to our newsletter to get our news &amp; deals delivered to you.</p>
                                <div className="newsletter-wrapper">
                                    <div id="mc_embed_signup2">
                                        <form action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&id=b49ef47a9a" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate dark-fields" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll2">
                                                <div className="mc-field-group input-group form-floating">
                                                    <input onChange={() => { }} type="email" defaultValue="" name="EMAIL" className="required email form-control" placeholder="Email Address" id="mce-EMAIL2" />
                                                    <label htmlFor="mce-EMAIL2">Email Address</label>
                                                    <input onChange={() => { }} type="submit" defaultValue="Join" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary" />
                                                </div>
                                                <div id="mce-responses2" className="clear">
                                                    <div className="response" id="mce-error-response2" style={{ display: 'none' }} />
                                                    <div className="response" id="mce-success-response2" style={{ display: 'none' }} />
                                                </div> 
                                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex={-1} defaultValue /></div>
                                                <div className="clear" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </>
    );
}