import Footer from "../Common/Footer";
import Header from "../Common/Header";
import SolutionGrid from "./SolutionGrid";
import { motion } from "framer-motion";

import BG from "../../../assets/img/photos/soln.webp";

export default function Solutions() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                {/* <section className="wrapper bg-soft-primary"> */}
                <section
                    className='wrapper image-wrapper bg-image bg-overlay bg-overlay-400'
                    style={{ backgroundPosition: "bottom" }}
                    data-image-src={BG}
                >
                    <div className='container pt-16 pb-16 pt-md-16 pb-md-16 text-center'>
                        <div className='row'>
                            <div className='col-md-7 col-lg-6 col-xl-5 mx-auto'>
                                <h1 className='display-1 mb-3 text-white'>
                                    Solutions
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='wrapper bg-light'>
                    <div className='container pb-14 pb-md-16 pt-5'>
                        <div className='row'>
                            <div className='col-lg-10 mx-auto'>
                                <SolutionGrid />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </motion.div>
    );
}
