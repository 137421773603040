import Footer from "../Common/Footer";
import Header from "../Common/Header";
import ProductList from "./ProductList";
import ProductBanner from "./ProductBanner";
import { motion } from "framer-motion";

export default function Products() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                <ProductBanner />
                <ProductList />
                <Footer />
            </div>
        </motion.div>
    );
}
