import "../../../assets/css/DemoListItem.css";

import Dev from "../../../assets/img/screen1.png";

import $ from "jquery";
// import Chart from 'chartjs-gauge';
// import 'chartjs-plugin-datalabels';
import { useEffect } from "react";
import BOUNDS from "../../../constant/BOUNDS";

export default function DemoListItem({ pageStack, item }) {
    const calculateRotate = (elem, to) => {
        const offset = elem.offset(),
            toOffset = to.offset(),
            center = {
                x: offset.left + elem.outerWidth() / 2,
                y: offset.top + elem.outerHeight() / 2,
            },
            toCenter = {
                x: toOffset.left + to.outerWidth() / 2,
                y: toOffset.top + to.outerHeight() / 2,
            },
            radians = Math.atan2(toCenter.x - center.x, toCenter.y - center.y),
            degree = radians * (180 / Math.PI) * -1 + 180;
        return degree;
    };
    const config = function (title, labels, data, value, minVal) {
        return {
            type: "gauge",
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        minValue: minVal,
                        value: value,
                        backgroundColor: ["green", "yellow", "orange"],
                        borderWidth: 0,
                    },
                ],
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: title,
                },
                layout: {
                    padding: {
                        bottom: 30,
                    },
                },
                needle: {
                    // Needle circle radius as the percentage of the chart area width
                    radiusPercentage: 2,
                    // Needle width as the percentage of the chart area width
                    widthPercentage: 3.2,
                    // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                    lengthPercentage: 80,
                    // The color of the needle
                    color: "rgba(0, 0, 0, 1)",
                },
                valueLabel: {
                    display: false,
                    formatter: (value) => {
                        return "" + Math.round(value);
                    },
                    color: "rgba(255, 255, 255, 1)",
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    borderRadius: 5,
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },

                plugins: {
                    datalabels: {
                        display: true,
                        formatter: (value, context) => {
                            return context.chart.data.labels[context.dataIndex];
                        },
                        // color: function (context) {
                        //     return context.dataset.backgroundColor;
                        // },
                        // color: '',
                        color: "rgba(255, 255, 255, 1.0)",
                        backgroundColor: "black",
                        font: {
                            size: 8,
                            // weight: 'bold'
                        },
                    },
                },
                // legend: {
                //     display: true,
                //     labels: {
                //         fontSize: 10
                //     }
                // }
            },
        };
    };
    useEffect(() => {
        let interval;
        let timeout;
        const container = $(".demoContainer"),
            element = $(".demoImage");
        const cursor = $("<div/>")
            .addClass("cursor")
            .html(`<img src=${Dev} alt="cc"/>`)
            .appendTo(container);

        $(container).on("mousemove", (e) => {
            cursor.toggle(
                $(e.target).is(element) || $(e.target).is($(".demoImage"))
            );
            cursor.css({
                "--x": e.pageX + "px",
                "--y": e.pageY + "px",
                "--r": calculateRotate(cursor, element) + 120 + "deg",
            });
        });
        container.on("mouseleave", (e) => {
            // cursor.removeClass("cursor");
            cursor.hide();
        });
        $(".demoImage")
            .on("mousedown", () => {
                $(".progress").css({
                    display: "block",
                });
                let curren = -1;
                clearInterval(interval);
                clearTimeout(timeout);
                //$('.backbut').hide();
                interval = setInterval(function () {
                    curren++;
                    $(".progress-bar").css({
                        width: `${curren}%`,
                    });
                }, 20);
                timeout = setTimeout(function () {
                    $(".progress").css({
                        display: "none",
                    });
                    $(".imageContainer").hide();
                    pageStack.current.push(".reportContainer");
                    cursor.hide();
                    $(".reportContainer").css({
                        display: "block",
                    });

                    let num = 1;
                    for (; num <= 5; num++) {
                        document.getElementById("chartHolder" + num).innerHTML =
                            "&nbsp;";
                    }
                    num = 0;
                    for (const p in item["properties"]) {
                        ++num;
                        document.getElementById(
                            "chartHolder" + num
                        ).innerHTML = `<canvas id="chart${num}"></canvas>`;
                        var ctx1 = document
                            .getElementById("chart" + num)
                            .getContext("2d");
                        const name = item["name"];
                        const remarks = BOUNDS[name][p]["remarks"];
                        const data = BOUNDS[name][p]["data"];
                        //console.log(name, remarks, data);

                        window[p] = new window.Chart(
                            ctx1,
                            config(
                                p,
                                remarks,
                                data.slice(1),
                                item["properties"][p]
                            ),
                            data[0]
                        );
                    }
                    $(".demoContainer").off("mousedown");
                    // cursor.hide();
                    container.css({
                        cursor: "default",
                    });
                    clearInterval(interval);
                }, 2000);
            })
            .on("mouseup mouseleave", function () {
                //$('.backbut').show();
                $(".progress").toggle();
                $(".progress-bar").css({
                    width: 0,
                });
                clearTimeout(timeout);
                clearInterval(interval);
            });
        return function () {
            $(container).off("mousemove");
            $(container).off("mouseleave");
            $(".demoImage").off("mousedown");
            $(".demoImage").off("mouseup mouseleave");
        };
    }, []);

    const btnClick = () => {
        let interval;
        let timeout;
        const container = $(".demoContainer"),
            element = $(".demoImage");
        const cursor = $("<div/>")
            .addClass("cursor")
            .html(`<img src=${Dev} alt="cc"/>`)
            .appendTo(container);
        //cursor.toggle();
        // cursor.css({
        //     '--x': element.offset().left + element.width() / 2 + 'px',
        //     '--y': element.offset().top + 'px',
        // });
        // cursor.css({
        //     '--r': calculateRotate(cursor, element) + 120 + 'deg'
        // });
        $(".progress").css({
            display: "block",
        });
        let curren = -1;
        clearInterval(interval);
        clearTimeout(timeout);
        //$('.backbut').hide();
        interval = setInterval(function () {
            curren++;
            $(".progress-bar").css({
                width: `${curren}%`,
            });
        }, 20);
        timeout = setTimeout(function () {
            $(".progress").css({
                display: "none",
            });
            $(".imageContainer").hide();
            pageStack.current.push(".reportContainer");
            $(".reportContainer").css({
                display: "block",
            });

            let num = 1;
            for (; num <= 5; num++) {
                document.getElementById("chartHolder" + num).innerHTML =
                    "&nbsp;";
            }
            num = 0;
            for (const p in item["properties"]) {
                ++num;
                document.getElementById(
                    "chartHolder" + num
                ).innerHTML = `<canvas id="chart${num}"></canvas>`;
                var ctx1 = document
                    .getElementById("chart" + num)
                    .getContext("2d");
                const name = item["name"];
                const remarks = BOUNDS[name][p]["remarks"];
                const data = BOUNDS[name][p]["data"];
                console.log(name, remarks, data, p);

                window[p] = new window.Chart(
                    ctx1,
                    config(p, remarks, data.slice(1), item["properties"][p]),
                    data[0]
                );
            }
            $(".demoContainer").off("mousedown");
            //cursor.toggle();
            //$('.backbut').show();
            // cursor.hide();
            container.css({
                cursor: "default",
            });
            clearInterval(interval);
        }, 2000);
        $(".Demobutton").css({
            display: "none",
        });
    };

    return (
        <div className='imageContainer'>
            <img
                alt='gr'
                style={{ width: "200px" }}
                className='demoImage'
                src='#'
            />
            <div className='text-center mt-4 help-text'>
                <p className='mb-0 mt-2'>Click and hold on the fruit</p>
                <p className='mb-0 mt-2'>OR</p>
            </div>
            <button
                className='btn btn-success'
                onClick={btnClick}
                style={{ marginTop: "20px" }}
            >
                Click to Scan the fruit
            </button>
            {/* <button className="btn btn-success Demobutton" onClick={buttonClick} style={{ marginTop: '20px' }}>Click to Scan the fruit</button> */}
        </div>
    );
}
