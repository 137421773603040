import ArticleGridView from "./ArticleGridView";
// import Pagination from "./Pagination";



export default function Articles() {
    return (
        <section className="wrapper bg-light">
            <div className="container pb-14 pb-md-16 pt-5">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        {/* <ArticleBigView /> */}
                        <ArticleGridView />
                        {/* <Pagination /> */}
                    </div>
                </div>
            </div>
        </section>
    );
}