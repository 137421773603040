import shivam from "../../../assets/img/profile/shivam.webp";
import jaanvi from "../../../assets/img/profile/jaanvi.webp";
import kartikey from "../../../assets/img/profile/kartikey.webp";
import himang from "../../../assets/img/profile/himang.webp";
import khushi from "../../../assets/img/profile/khushi.webp";
import ayush from "../../../assets/img/profile/ayush.webp";
import vikas from "../../../assets/img/profile/vikas.webp";
import deshraj from "../../../assets/img/profile/deshraj.webp";
import amit from "../../../assets/img/profile/amit.webp";
import ankit from "../../../assets/img/profile/ankit.webp";
import vishal from "../../../assets/img/profile/vishal.webp";
import { useState } from "react";

export default function TeamInfo() {
    const [n1, setN1] = useState("Show More");
    const [n2, setN2] = useState("Show More");
    const [n3, setN3] = useState("Show More");
    const [n4, setN4] = useState("Show More");
    const [n5, setN5] = useState("Show More");
    const [n6, setN6] = useState("Show More");
    const [n7, setN7] = useState("Show More");
    const [n8, setN8] = useState("Show More");
    const [n9, setN9] = useState("Show More");
    const [n10, setN10] = useState("Show More");
    const [n11, setN11] = useState("Show More");
    return (
        <>
            <section className='wrapper bg-light'>
                <div className='container pb-14 pb-md-16'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto mt-5'>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={amit}
                                                srcSet={amit}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Amit Srivastava</h2>
                                        <p>
                                            <strong>
                                                Co-Founder and CEO, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:amit@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/asrivastavaiitm'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Amit is the CEO and co-founder of
                                            InfyU LABS. He completed his
                                            Bachelors in Engineering Physics
                                            from IIT Madras with minors in
                                            Management studies.
                                        </p>

                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample1'
                                        >
                                            <p>
                                                He is an entrepreneur, analyst
                                                and strategist. He dreams of
                                                creating an innovation hub where
                                                dreamers can work towards
                                                developing innovations that can
                                                solve many daily challenges.
                                                This motivation drove him
                                                towards working in AgTech and
                                                working diligently towards
                                                reducing food wastage.
                                            </p>
                                            <p>
                                                He is an avid astronomy
                                                enthusiast and has even gained
                                                professional experience in the
                                                field of Radio Astronomy from
                                                national and international
                                                laboratories under world
                                                renowned scientists.
                                            </p>
                                            <p>
                                                He is working on developing
                                                business strategies and product
                                                development to ideate new
                                                product technologies to reform
                                                the agricultural situation in
                                                India.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample1'
                                            aria-expanded='false'
                                            aria-controls='collapseExample1'
                                            onClick={() => {
                                                setN1(
                                                    n1 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {n1}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={ankit}
                                                srcSet={ankit}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        {/* text-md-left text-center */}
                                        <h2>Ankit Chauhan</h2>
                                        <p>
                                            <strong>
                                                Co-Founder and CTO, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:ankit@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/ankit961'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Ankit is the CTO and co-founder of
                                            InfyU LABS. He completed his
                                            bachelors from UPTU UP Technical
                                            University and pursued Masters in
                                            Engineering from IIT Madras.
                                        </p>

                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample2'
                                        >
                                            <p>
                                                He has published various
                                                research papers during his
                                                Masters and while he was in
                                                Hasso Plattner Institute, a
                                                reputed research institute in
                                                Germany on game theory and
                                                machine learning. He has been
                                                employing this experience to
                                                work on supply chain issues in
                                                agriculture.
                                                <br />
                                                Ankit is a tech innovator and
                                                reformist who likes to solve
                                                complex real-world problems with
                                                innovative solutions. Ankit has
                                                more than six years of R&D
                                                experience in industries and
                                                academia.
                                            </p>
                                            <p>
                                                He was born and raised in a
                                                family of farmers and has
                                                personally experienced the
                                                plight the community experiences
                                                at each and every step. This has
                                                been his inspiration for working
                                                in AgTech.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample2'
                                            aria-expanded='false'
                                            aria-controls='collapseExample2'
                                            onClick={() => {
                                                setN2(
                                                    n2 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {/* <i className="fas fa-angle-right"></i> */}
                                            {n2}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={vishal}
                                                srcSet={vishal}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Vishal Chauhan</h2>
                                        <p>
                                            <strong>
                                            Head of Sales & Growth, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:vishal@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/vishalchauhan4920'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                        </span>
                                        <p>
                                        Vishal, an alumnus of IIT Kharagpur. He is an entrepreneur, a seasoned Business leader and has 4+ years of experience in applying exceptional planning, problem-solving techniques towards enhancing Sales funnel, instrumental growth and day-to-day operations.
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample8'
                                        >
                                            <p>
                                            He has worked in India's top most Unicorn startups like OYO, Vedantu & BYJUs in their Business, Strategy, Product Marketing, Sales & Customer experience teams.
                                            </p>
                                            <p>
                                            He is working on plans and strategies for developing business and achieving the company’s sales goals. Working collaboratively across teams - including Engineering, Product and Marketing to meet business objectives. Evangelize the product and personally help partner channels to close the largest deals.
                                            </p>
                                            <p>
                                            Techie by mind, Traveler by heart.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample8'
                                            aria-expanded='false'
                                            aria-controls='collapseExample8'
                                            onClick={() => {
                                                setN11(
                                                    n11 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {n8}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            

                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={deshraj}
                                                srcSet={deshraj}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Deshraj Singh </h2>
                                        <p>
                                            <strong>
                                                Operations Manager, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:deshraj@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/deshraj-chauhan-455a171a2'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.facebook.com/profile.php?id=100006734718731'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-facebook'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Deshraj is an Operations Manager at
                                            InfyU LABS. He completed his B.Tech
                                            in Computer Science and Engineering
                                            from Dr. APJ Abdul Kalam Technical
                                            University, Lucknow in 2019.
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample10'
                                        >
                                            <p>
                                                He is well versed in the areas
                                                of client relation management
                                                and much more. Deshraj joined
                                                the organisation as an intern in
                                                November 2019 and was soon able
                                                to work his way to become a core
                                                team member. He has been working
                                                as an operation manager at InfyU
                                                LABS since February 2020.
                                            </p>
                                            <p>
                                                Growing up, he has worked in
                                                agriculture fields himself and
                                                believes that spreading
                                                awareness about agtech products
                                                and their benefits is the first
                                                step towards achieving a food
                                                secure world.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample10'
                                            aria-expanded='false'
                                            aria-controls='collapseExample10'
                                            onClick={() => {
                                                setN10(
                                                    n10 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {n10}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={vikas}
                                                srcSet={vikas}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Vikas Kumar</h2>
                                        <p>
                                            <strong>
                                                Senior Software Developer, InfyU
                                                LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:vikas@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/vikas-kumar-056a171a2/'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.facebook.com/profile.php?id=100012541121578'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-facebook'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Vikas Kumar is a Senior Software
                                            Developer at InfyU LABS. He
                                            completed his B.Tech in Computer
                                            Science and Engineering from Dr. APJ
                                            Abdul Kalam Technical University,
                                            Lucknow in 2019.{" "}
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample9'
                                        >
                                            <p>
                                                He started working with the
                                                organisation in September 2019
                                                and became an integral part of
                                                the core team in November 2019.
                                                He has been working as an
                                                android application developer at
                                                InfyU LABS since February 2020.
                                            </p>
                                            <p>
                                                He comes from a farmer family
                                                background and has a keen
                                                understanding of the elemental
                                                issues. While he is a tech
                                                enthusiast he also loves
                                                theatre, drama, cricket and
                                                travelling.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample9'
                                            aria-expanded='false'
                                            aria-controls='collapseExample9'
                                            onClick={() => {
                                                setN9(
                                                    n9 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {n9}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={ayush}
                                                srcSet={ayush}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Ayush Kharkia </h2>
                                        <p>
                                            <strong>
                                                Project Manager, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:ayush@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/ayushkharkia'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Ayush Kharkia works as a Project Manager at
                                            InfyU LABS. He has been associated with
                                                InfyU LABS for 2 years.{" "}
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample7'
                                        >
                                            <p>
                                                 He is
                                                immensely interested in the
                                                StartUp ecosystem and believes
                                                that AgTech is the most critical
                                                area for ensuring food safety
                                                and overall well being of the
                                                agriculture industry but
                                                unfortunately is development
                                                thirsty.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample7'
                                            aria-expanded='false'
                                            aria-controls='collapseExample7'
                                            onClick={() => {
                                                setN7(
                                                    n7 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {n7}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={kartikey}
                                                srcSet={kartikey}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Kartikey Srivastava</h2>
                                        <p>
                                            <strong>
                                                Electronic and CAD Designer,
                                                InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:kartikey@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.instagram.com/kartikey._.00/'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-instagram'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Kartikey is an Electronics and CAD
                                            designer at InfyU LABS. He received
                                            an Electrical Engineering Diploma
                                            from the Board of Technical
                                            Education Uttar Pradesh (BTEUP),
                                            Lucknow in 2018.
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample5'
                                        >
                                            <p>
                                                He has been working with InfyU
                                                LABS since January 2021. Apart
                                                from being a CAD designer he is
                                                also learning the nuances of the
                                                supply chain and is working on
                                                various parameters for improving
                                                quality. In his free time, he
                                                likes to play chess and cricket.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample5'
                                            aria-expanded='false'
                                            aria-controls='collapseExample5'
                                            onClick={() => {
                                                setN5(
                                                    n5 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {/* <i className="fas fa-angle-right"></i> */}
                                            {n5}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={shivam}
                                                srcSet={shivam}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Shivam Chauhan</h2>
                                        <p>
                                            <strong>
                                                Operation's Team Member, InfyU
                                                LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:shivam@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Shivam is an operation team member
                                            at InfyU LABS. He completed his B.SC
                                            in Biology from MJPR University in
                                            2019.
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample3'
                                        >
                                            <p>
                                                He has been associated with
                                                InfyU LABS since 2021. He has a
                                                keen interest in learning about
                                                new technologies in the AgTech
                                                market. He believes that little
                                                drops make the mighty ocean and
                                                taking small steps for
                                                overcoming a challenge in the
                                                agriculture industry can prove
                                                to be constructive for the
                                                farmer’s community.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample3'
                                            aria-expanded='false'
                                            aria-controls='collapseExample3'
                                            onClick={() => {
                                                setN3(
                                                    n3 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {/* <i className="fas fa-angle-right"></i> */}
                                            {n3}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='card p-5 mb-3'>
                                <div className='row gx-lg-8 gx-xl-12 gy-2 mb-0 mb-md-0 align-items-center justify-content-center'>
                                    <div className='col-lg-3'>
                                        <figure className='rounded mb-0 text-center'>
                                            <img
                                                className='rounded-circle'
                                                style={{ maxWidth: "200px" }}
                                                src={himang}
                                                srcSet={himang}
                                                alt=''
                                            />
                                        </figure>
                                    </div>
                                    <div className='col-lg-8'>
                                        <h2>Hemang Prakhar</h2>
                                        <p>
                                            <strong>
                                                Product Developer, InfyU LABS
                                            </strong>
                                        </p>
                                        <span className='d-flex flex-row'>
                                            <a
                                                className='pb-4 px-2'
                                                href='mailto:hemang@infyulabs.com'
                                            >
                                                <i className='fas fa-envelope'></i>
                                            </a>
                                            <a
                                                className='pb-4 px-2'
                                                href='https://www.linkedin.com/in/hemang-prakhar'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <i className='fab fa-linkedin'></i>
                                            </a>
                                        </span>
                                        <p>
                                            Hemang Prakhar is a Product
                                            Developer at InfyU LABS. He is
                                            pursuing his B.Tech in Computer
                                            Science with specialisation in the
                                            Internet of Things from UPES,
                                            Dehradun.
                                        </p>
                                        <div
                                            className='collapse mt-2'
                                            id='collapseExample6'
                                        >
                                            <p>
                                                He has been working with InfyU
                                                LABS since 2021. He is a
                                                creative thinker, who
                                                manipulates existing
                                                technologies to refine them,
                                                make them compact and
                                                cost-effective. His father was a
                                                businessman who left his
                                                business to join farming and
                                                then went on to develop exotic
                                                vegetables.
                                            </p>
                                            <p>
                                                He enjoys playing different instruments,
                                                swimming and reading. His
                                                favourite books are Rich Dad and
                                                Poor Dad and The Secret.
                                            </p>
                                        </div>
                                        <a
                                            className=''
                                            type='button'
                                            data-toggle='collapse'
                                            data-target='#collapseExample6'
                                            aria-expanded='false'
                                            aria-controls='collapseExample6'
                                            onClick={() => {
                                                setN6(
                                                    n6 === "Show More"
                                                        ? "Show Less"
                                                        : "Show More"
                                                );
                                            }}
                                        >
                                            {/* <i className="fas fa-angle-right"></i> */}
                                            {n6}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
