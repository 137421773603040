import cc from '../../../../assets/img/cloudComputing.webp';

export default function CloudComputingHeader() {
    return (
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-500 text-white" data-image-src={cc}>
            <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-6 mt-6 text-white">Cloud Computing - The Genie in a bottle</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}