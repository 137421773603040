import cloud1 from '../../../../assets/img/Cloud1.png';
import cloud2 from '../../../../assets/img/Cloud2.png';

export default function CloudComputingInfo() {
    return(
        <section className="wrapper bg-white">
            <div className="col-md-8 col-lg-8 mx-auto">
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">What is Cloud Computing?</h3>
                            <p className="mb-5">
                            Cloud computing is the on-demand distribution of computing resources like data storage, computing power, software, server etc, over the internet.
                            </p>
                            <p className="mb-5">
                            Users can access these resources as and when required on an ad-hoc basis without the hassle of directly owning or managing these services. The services are offered on a pay-per-use basis where the user only pays for what they use and when they use it, making it a very economical option for any business. Because the cloud is infinite in size, users do not need to worry about it running out of capacity when they want to scale the business.
                            </p>
                            <p className="mb-5">
                            Cloud computing is the on-demand distribution of computing resources like data storage, computing power, software, server etc, over the internet. These services are hosted by cloud providers over the internet (the cloud) to offer cost saving, increased productivity, speed and efficiency, faster innovation, flexible resources, and economies of scale.
                            </p>                 
                        </div>
                        <div className="col-lg-4 order-lg-2 position-relative">
                            <figure className="rounded mb-0"><img src={cloud1} srcSet={cloud1} alt="" /></figure>
                        </div>
                    </div>
                </div>
                <div className=" py-2 mx-4">
                    <p className="mb-5">
                    Another most important advantage cloud offers is remote access from anywhere you go. As long as an electronic device has access to the web, it has access to the data and the software programs they stored on the cloud.
                    </p>
                    <p className="mb-5">
                    Everything is on the cloud nowadays. You are probably using cloud computing right now, even if you don’t realize it. If you use an online service to send email, edit documents, watch movies or TV, listen to music, play games or store pictures and other files, it is likely that cloud computing is making it all possible behind the scenes.
                    </p>
                    <p className="mb-5">
                    From small startups to multinationals, from government agencies to non-profits— all are adopting the technology for all sorts of reasons.
                    </p>
                    <p className="mb-5">
                    Cloud computing services can be classified into infrastructure as a service (IaaS), platform as a service (PaaS) and software as a service (SaaS). As their name suggests different computing resources are offered as services under each category. There are also mainly 3 types of Clouds.
                    </p>
                    <ul>
                        <li className='px-lg-5 px-xxl-8 mt-2 px-3'><strong><a href="https://www.ibm.com/cloud/learn/public-cloud" target="_blank" rel="noopener noreferrer">Public Cloud -</a></strong> Services are available for sale to anyone on the internet. Multiple users share the resources. AWZ, Microsoft Azure and Google cloud are 3 most common examples of a public cloud. </li>
                        <li className='px-lg-5 px-xxl-8 mt-2 px-3'><strong><a href="https://www.ibm.com/cloud/learn/introduction-to-private-cloud" target="_blank" rel="noopener noreferrer">Private Cloud -</a></strong> It is a private network of hated services to a limited number of selected people with certain access and permissions. </li>
                        <li className='px-lg-5 px-xxl-8 mt-2 px-3'><strong><a href="https://azure.microsoft.com/en-in/overview/what-is-hybrid-cloud-computing/" target="_blank" rel="noopener noreferrer">Hybrid Cloud -</a></strong> It is the best suitable mix of on-premise infrastructure, private cloud and public cloud as required by certain organizations to support their working model. </li>
                    </ul>
                </div>
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-4 position-relative">
                            <figure className="rounded mb-0"><img src={cloud2} srcSet={cloud2} alt="" /></figure>
                        </div>
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">How does InfyU rely on Cloud Computing?</h3>
                            <p className="mb-5">
                            To ensure efficiency and security, all the data that InfyU deals with is stored on a cloud platform. InfyZer is connected with a mobile application through which data captured by the device is shared to the cloud servers. Smart cloud based deep learning algorithms and software processes the provided data and in just a few seconds, all the harmful chemical substances (if present) are displayed on the mobile app screen. We recognize all the benefits that cloud offers and make the most of it. Automatic grading and sorting of fruits and vegetables is powered by our cloud based algorithms.
                            </p>
                                           
                        </div>
                    </div>
                </div>
                

               
            </div>
        </section>
    )
}