
import Transparency from '../../../assets/img/transparency.jpg';
import Farming from '../../../assets/img/farming.jpg';
import Spectroscopy from '../../../assets/img/spectroscopy.jpg';


export default function ArticleGridView() {
    return (
        <div className="blog grid grid-view">
            <div className="row isotope gx-md-8 gy-8 mb-8">
                <article className="item post col-md-6">
                    <div className="card">
                        <figure style={{ height: "250px" }} className="card-img-top overlay overlay1 hover-scale">
                            <a href="/blog1">
                                <img src="https://www.ukri.org/wp-content/uploads/2021/09/UKRI-270921-FutureFarming-TransformingFoodProduction-Getty-735x490.jpg" alt="Traceability in Agriculture Food Supply Chain" loading='lazy' />
                            </a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                                {/* <div className="post-category text-line">
                                    <a href="/#" className="hover" rel="category">Coding</a>
                                </div> */}
                                {/* /.post-category */}
                                <h2 style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 1, WebkitBoxOrient: "vertical"}}className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/blog1">Traceability in Agriculture Food Supply Chain</a></h2>
                            </div>
                            {/* /.post-header */}
                            <div className="post-content">
                                <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Today, we are in a globalised agricultural market. Cross border trade demands and approvals became tougher, not only assuring the quality but also of package and practices followed the origin of crop and other farming details became mandatory.</p>
                            </div>
                            {/* /.post-content */}
                        </div>
                        {/*/.card-body */}

                        {/* /.card-footer */}
                    </div>
                    {/* /.card */}
                </article>
                {/* /.post */}
                <article className="item post col-md-6">
                    <div className="card">
                        <figure style={{ height: "250px" }} className="card-img-top overlay overlay1 hover-scale"><a href="/blog2"> <img src={Farming} alt="covid impact on agri" loading='lazy' /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                               <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/blog2">Impact of COVID-19 on Indian Agriculture</a></h2>
                            </div>
                            <div className="post-content">
                                <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Most countries used lockdown as the primary tool to control the spread of the virus; same India had opted to it. Due to this sudden lockdown agriculture sector is highly impacted. Right from production to consumption stage pandemic is hit badly.</p>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="item post col-md-6">
                    <div  className="card">
                        <figure style={{ height: "250px" }} className="card-img-top overlay overlay1 hover-scale"><a href="/blog3"> <img src="https://m.economictimes.com/thumb/msid-79459311,width-1200,height-936,resizemode-4,imgsize-283339/agritech.jpg" alt="How Agritech helped during pandemic" loading='lazy' /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                               <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/blog3">How Agritech helped during pandemic</a></h2>
                            </div>
                            <div className="post-content">
                                <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Agriculture industry found itself in a total disarray as mobility restrictions led to shortage of farm laborers. Input supply chains froze, farmers struggled to access markets and storage facilities, cost of products pinched, fluctuating prices and limited access to financing underscored the urgent need to transform agriculture.</p>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="item post col-md-6">
                    <div  className="card">
                        <figure style={{ height: "250px" }} className="card-img-top overlay overlay1 hover-scale"><a href="/blog3"> <img src="https://static.businessworld.in/article/article_extra_large_image/1478073024_Frbdt4_agritech.jpg" alt="Using agritech to solve the Indian export crisis" loading='lazy' /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                               <h2 style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 1, WebkitBoxOrient: "vertical"}} className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/blog3">Using agritech to solve the Indian export crisis</a></h2>
                            </div>
                            <div className="post-content">
                                <p style={{display: "-webkit-box", overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>Contributing to about 15% of India’s GDP, the agricultural sector sustains almost half of the country’s population. With more than 265 mn people engaged in farming, India ranks second in fruits and vegetable production in the world, after China.</p>
                            </div>
                        </div>
                    </div>
                </article>
                {/* /.post */}
                
                {/* <article className="item post col-md-6">
                    <div style={{ height: "520px" }} className="card">
                        <figure style={{ height: "250px" }} className="card-img-top overlay overlay1 hover-scale"><a href="/blog3"> <img src={Spectroscopy} alt="" /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/blog3">Spectroscopy - Science that can answers our questions</a></h2>
                            </div>
                            <div className="post-content">
                                <p>Spectroscopy is the study of absorption and emission of electromagnetic radiation by matter or an object. It is the science of interaction between light and matter as a function of light’s wavelength or frequency.</p>
                            </div>
                        </div>
                    </div>
                </article> */}
                {/* /.post */}

                {/* /.post */}

                {/* /.post */}
            </div>
            {/* /.row */}
        </div>
    );
}