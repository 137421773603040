import Footer from '../Common/Footer';
import Header from '../Common/Header';

import BG from "../../../assets/img/photos/Solutions-1.png";

export default function Careers() {
    return (
        <div className="content-wrapper">
            <Header/>

            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" data-image-src={BG}>
                <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center">
                    <div className="row">
                        <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                            <h1 className="display-1 mb-3 text-white">Careers</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-white">
                <div className="col-md-8 col-lg-8 mx-auto">
                    <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">Work With Us</h2>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    We are working towards shaping the future of the agricultural industry through agritech. Our mantra is simple - empowering farmers and retailers through our tech devices to improve the lives of the public at large. The organisation is built on values of commitment, excellence, integrity and teamwork. We emphasise on growth, career as well as company's growth. Our company is the best place to develop your agritech acumen. 
                    </p>
                    <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">
                        Experienced Opportunities
                    </h2>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    InfyU LABS internship programme is curated to give your future in agritech a stable and focused head start. If you are a student with an engineering background or agricultural background and want to work with a company that guides you painstakingly through your internship stint, please get in touch with our human resource manager. 
                    </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    Write to us at <strong><a href="mailto:hello@infyulabs.com">hello@infyulabs.com</a></strong> with your latest resume and a cover letter mentioning why you would like to join our firm and what are your expectations from us. You may also mail us in case you have any queries regarding the internship programme we offer. 
                    </p>
                    <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">
                        Welcoming New Grads
                    </h2>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    We invest in talent development. If you are a graduate with an engineering background or agricultural background and are looking for a company that values your contribution and efforts, please get in touch with our human resource manager. 
                    </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    We look for professionals with diverse skill sets from the technology and agriculture fields. Strong work ethic, decisiveness, commercial awareness and collaborative approach. 
                    </p>
                    <p className="px-lg-5 px-xxl-8 mt-2 px-3">
                    If you are an interested applicant, please contact our human resource manager for further details. You may write to us with queries regarding our graduate hiring programs at <strong><a href="mailto:hello@infyulabs.com">hello@infyulabs.com</a></strong>.
                    </p>
                </div>
            </section>

            <Footer/>
        </div>
    )
}