

// import Bg from '../../../assets/img/photos/blog.JPG'


export default function PostBanner() {
    return (
        <section className="wrapper image-wrapper bg-image bg-overlay text-white" data-image-src="https://www.ukri.org/wp-content/uploads/2021/09/UKRI-270921-FutureFarming-TransformingFoodProduction-Getty-735x490.jpg">
            <div className="container pt-17 pb-13 pt-md-19 pb-md-17 text-center">
                <div className="row">
                    <div className="col-md-10 col-xl-8 mx-auto">
                        <div className="post-header">
                            <div className="post-category text-line text-white">
                                <a href="/" className="text-reset" rel="category">InfyULabs</a>
                            </div>
                            <h1 className="display-1 mb-4 text-white">Traceability in Agriculture Food Supply Chain</h1>
                            {/* <ul className="post-meta text-white">
                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>5 Jul 2021</span></li>
                                <li className="post-author"><i className="uil uil-user"></i><a href="/#" className="text-reset"><span>By Ragahva Segu</span></a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}