import FirstToSee from "./FirstToSee";
import HomeFooter from "./HomeFooter";
import HomeHeader from "./HomeHeader";
import PreviousProjects from "./PreviousProjects";
import ServiceOffered from "./ServiceOffered";
// import Stats from './Stats';
// import Team from "./Team";
import { motion } from "framer-motion";

export default function Homepage() {
    
    return (
        <motion.div
            // initial={{ opacity: 0.25 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0.5 }}
            // transition={{duration: 1}}
        >
            <div className='content-wrapper'>
                <HomeHeader />
                <FirstToSee />
                <ServiceOffered />
                <PreviousProjects />
                <HomeFooter />
            </div>

        </motion.div>
    );
}
