
 

export default function PostBody() {
    return (
        <article className="post">
            <div className="post-content mb-5" style={{fontFamily: 'Calibri, sans-serif'}}>
                <h2>How Agritech helped during pandemic</h2>
                <p>
                Agriculture industry found itself in a total disarray as mobility restrictions led to shortage of farm laborers. Input supply chains froze, farmers struggled to access markets and storage facilities, cost of products pinched, fluctuating prices and limited access to financing underscored the urgent need to transform agriculture.
                </p>
                <p>
                The worst part of Covid-19 induced nationwide lockdown was that it coincided with the country’s peak harvesting time. Summer fruits and vegetables were ready to pick; wheat, barley and paddy were set for harvest, but the nation came to a grinding halt.
                </p>
                <p>
                Despite the initial setback amid the lockdown, agriculture is the only sector to have reported a growth in the first quarters for 3 years in a row—and this was only possible due to Agritech. Interestingly, Agritech grew 4.5 percent during the June quarter, as per the National Statistical Office's latest report. 
                </p>
                <p>
                Agritech helped the sector stabilize and churn volume-based business. Solutions offered by agritech startups were agile and were able to withstand market inefficiencies. 
                </p>
                <p>
                During COVID, farmers found it very difficult to source input supplies locally. Companies selling agri-inputs online jumped at the opportunity and offered contactless delivery of required tools. Farmers could go online and choose from a variety of options and price ranges. They were able to procure inputs easily and hassle-free thanks to agritech. 
                </p>
                <p>
                Similarly on the output side, since mandis were not operating normally and posed serious risk of COVID contraction, eMandis and eSelling surged. Online platforms enabled farmers to sell their produce remotely. If not for online selling, the harvest would have decayed sitting on farms or farmers would have been forced to sell it at extremely low prices. 
                </p>
                <p>
                Agritech also helped with the information, education and advisory part. When farmers had doubts about growing, buying or harvesting, a number of them turned to online advisory forums and discussion groups when they were not able to meet their peers in person. Mobile applications were sending timely climate notifications, educational videos and training content to interested farmers so that they can plan the next harvest productively. 
                </p>
                <p>
                Using technology to deal with COVID challenges, made a lot of farmers comfortable with it. 
                </p>
                <h2>Summing Up</h2>
                <p>
                The events that unfolded in the past 2 years have exposed quite a fews loopholes in our agricultural system and left us with a lot to work on. While we recover from the economic shock induced by the pandemic and fight potential food inflation, the combination of technology and our farmer community’s knowledge base can help improve overall production and quality of food.
                </p>
                <p>
                Agriculture and it’s foundation goes a long way back in our history and there is no way we will be able to flourish the industry if we do not place farmers at the heart of it. Indian farming practices have been passed down to us from generations - years of learning, hard work and experiences combined to bring us where we are today. We have technology at our disposal like never before and the trick is not just the use of it but the use of it in the right amount at the right places in the right way. 
                </p>
            </div >
        </article >
    );
}