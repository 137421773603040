export default function SolutionGrid() {
    return (
        <div className='wrapper bg-light'>
            {/* <div className="blog grid grid-view"> */}
            <div className='container py-12 py-md-12'>
                {/* <div className="row isotope gx-md-8 gy-8 mb-8"> */}

                <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
                    <article className='item post col-md-5 m-2'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='post-header'>
                                    <h2 className='post-title h3 mt-1 mb-3'>
                                        <a
                                            className='link-dark'
                                            href='/aggregators'
                                        >
                                            Aggregators and Cold Storage
                                        </a>
                                    </h2>
                                </div>
                                <div className='post-content'>
                                    <p
                                        style={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitLineClamp: 5,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        Aggregators are very important players
                                        of the agricultural landscape. They are
                                        centralized bridges which connect
                                        farmers and sellers. Inward flow of
                                        products from several sources get
                                        accumulated and are supplied to various
                                        small and large sellers via aggregators.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article className='item post col-md-5 m-2'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='post-header'>
                                    <h2 className='post-title h3 mt-1 mb-3'>
                                        <a
                                            className='link-dark'
                                            href='/retailers'
                                        >
                                            Retailers
                                        </a>
                                    </h2>
                                </div>
                                <div className='post-content'>
                                    <p
                                        style={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitLineClamp: 5,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        Quality is gradually becoming one of the
                                        most important criteria for the end
                                        consumer. The importance of quality
                                        among the end consumer owing to health
                                        awareness and pandemic is driving
                                        retailers to source the best quality
                                        produce.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article className='item post col-md-5 m-2'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='post-header'>
                                    <h2 className='post-title h3 mt-1 mb-3'>
                                        <a
                                            className='link-dark'
                                            href='/farmers'
                                        >
                                            Farmers
                                        </a>
                                    </h2>
                                </div>
                                <div className='post-content'>
                                    <p
                                        style={{
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitLineClamp: 5,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        Around two million tonnes of pesticides
                                        are applied, yet 20 to 40 per cent of
                                        the global crop yields are lost to pests
                                        and diseases, as per the Food and
                                        Agriculture Organization (FAO) of the
                                        United Nations (UN).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>

                {/* <article className="item post col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="post-header">
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/more">And More</a></h2>
                            </div>
                            <div className="post-content">
                                <p>
                                Rising population and increasing food demand intertwined with pandemics and lockdowns have impacted the already strained global supply chains immensely. 
                                </p>
                            </div>
                        </div>
                    </div>
                </article> */}
            </div>
        </div>
    );
}
