import spectro2 from '../../../../assets/img/Spectroscopy2.png';
import spectro1 from '../../../../assets/img/Spectroscopy1.png';

export default function SpectroscopyInfo() {
    return (
       <section className="wrapper bg-white"> 
            <div className="col-md-8 col-lg-8 mx-auto">
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">What is Spectroscopy?</h3>
                            <p className="mb-5">
                            Spectroscopy is the study of absorption and emission of electromagnetic radiation by matter or an object. It is the science of interaction between light and matter as a function of light’s wavelength or frequency.
                            </p>
                            <p className="mb-5">
                            Too many jargons? Don't worry, we will simplify it for you.  
                            </p>
                            <p className="mb-5">
                            So, when any material is exposed it emits electromagnetic radiation, it tells us a story; and examination of spectral response of an object tells us a lot about it.
                            </p>
                            <p className="mb-5">
                            In simple terms a ray of light is sent to the object under examination, how the object responds to this electromagnetic light will describe what is the chemical composition, nature, physical structure and other characteristics of that object at atomic, molecular and macro level.
                            </p>               
                        </div>
                        <div className="col-lg-4 order-lg-2 position-relative">
                            <figure className="rounded mb-0"><img src={spectro2} srcSet={spectro2} alt="" /></figure>
                        </div>
                    </div>
                </div>
                <div className=" py-2 mx-4">
                    {/* <div className="row gy-10 mt-0 align-items-center"> */}
                        {/* <div className="col-lg-8"> */}
                            <p className="mb-5">
                            The fundamental ground of spectroscopy is the fact that light is made of different wavelengths and each wavelength corresponds to a different frequency. Each element which contains atoms has a unique light spectrum described by frequencies of light it emits or absorbs. The concept that each atomic element has its own distinct spectral signature allowed spectroscopy to be applied to a wide range of areas, each with a distinct aim that could be reached by various spectroscopic processes. One of the most common examples is it being used in medical space for Magnetic Resonance Imaging (MRI). Similarly, spectroscopy is applied to virtually all technical science and technology fields like physics, chemistry, astronomy.
                            </p>
                        {/* </div> */}
                    {/* </div> */}
                </div>
                <div className=" py-2 mx-4">
                    <div className="row gy-10 mt-0 align-items-center">
                        <div className="col-lg-4 position-relative">
                            <figure className="rounded mb-0"><img src={spectro1} srcSet={spectro1} alt="" /></figure>
                        </div>
                        <div className="col-lg-8">
                            <h3 className="display-6 mb-4">Why are we talking about spectroscopy?</h3>
                            <p className="mb-5">
                            Quality assurance of fruits and vegetables by InfyU LABS is an example of application of visible/Near Infrared Spectroscopy (NIR) for non- destructive testing. InfyU’s quality check device- Infyzer, uses the principle of spectroscopy powered by Machine learning to test fruits and vegetables within seconds. 
                            </p>
                            <p className="mb-5">
                            A harmless infrared radiation is sent out by Infyzer that will penetrate up to 5 cm into the fruit, interact with its internal composition and send back a response signal. The response signal is then sent to the cloud server for analysis. There are various analysis models on the cloud server which will provide fruit’s health, chemical composition, other interdependent factors and a consolidated health report.
                            </p>
                            <p className="mb-5">
                            Spectroscopy started by Newton splitting light with a prism and calling it optics. Now it has evolved to a great extent. On a wider scale, spectroscopy definition now also includes the study of interactions between particles such as electrons, protons and ions, as well as their interaction with other particles as a function of their collision energy.
                            </p>              
                        </div>
                        
                        {/*/column */}
                    </div>
                </div>

                {/* <p className="lead px-lg-5 px-xxl-8 px-3">
                Food testing currently is an inaccessible, tedious and costly process as it requires technical staff such as lab technicians and expensive lab infrastructure. Additionally, results from conventional lab testing usually take a couple of days to weeks. Additional costs are incurred for transporting sample fruits and vegetables to laboratories. A chunk of fruits and vegetables are also wasted due to destructive testing in place. If a bad quality consignment of fruits or vegetables is accepted at the initial stage of the supply chain, it becomes unsellable by the time it reaches the consumer. We are working towards bridging this gap since 2019. 
                </p>
                <h2 className="px-lg-5 px-xxl-8 mt-10 px-3">Our Role</h2>
                <p className="lead px-lg-5 px-xxl-8 px-3">
                We are trying to build an International platform for Indian Fruit cultivators. We, at InfyU LABS, are working to develop phone connected technology to check the quality of fruits and eliminate the fallouts of manual/ laboratory checks. We aim to reduce food wastage and improve Food Safety using technology.
                </p>
                <h2 className=" px-lg-5 px-xxl-8 mt-10 px-3">Our Solutions</h2>
                <p className="lead px-lg-5 px-xxl-8 px-3">
                We have diligently worked on creating a wide range of cost effective, user friendly products. These can help in carrying out tests in the absence of technical infrastructure and without sophisticated and expensive machines.
                </p>
                <p className="lead px-lg-5 px-xxl-8 px-3">
                The algorithms used will give you the most accurate results instantly and easily. The test results will have a detailed report of the chemical composition of the tested fruit or vegetable. Such reports will help the user analyse the chemical composition of fruits and vegetables to make critical decisions at the time of accepting the consignment.
                </p>
                <p className="lead px-lg-5 px-xxl-8 px-3">
                These easy to use products will cater to different levels of supply chains and help make food security a reality. Some of our products eliminate the need of transporting fruits and vegetables as the food testing can be completed right at the time of procurement further saving additional transportation charges.
                </p>
                 */}
            </div>
                
        </section>
    )
}