import BG from '../../../assets/img/photos/news.webp';


export default function NewsBanner() {
    return( 
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" data-image-src={BG}>
            <div className="container pt-16 pb-16 pt-md-16 pb-md-16 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-3 text-white">News</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}