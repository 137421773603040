import BG from "../../../assets/img/photos/Product-banner.webp";



export default function BlogBanner() {
    return (
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" style={{backgroundPosition: "bottom"}} data-image-src={BG}>

        {/* <section className="wrapper bg-soft-primary"> */}
            <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-3 mt-3 text-white">Our Products</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}