
import PostBody from "./PostBody";
// import One from '../../../assets/img/transparency.jpg'




export default function Post() {
    return (
        <div className="col-lg-8">
            <div className="blog single">
                <div className="card">
                    {/* <figure className="card-img-top"><img src={One} alt="" /></figure> */}
                    <div className="card-body">
                        <div className="classic-view">
                            <PostBody />
                        </div>
                        <hr />
                        {/* <AuthorInfo /> */}
                        <hr />
                        {/* <OtherPosts /> */}
                        {/* <hr />
                        <Comments />
                        <hr />
                        <CommentForm /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}