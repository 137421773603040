import BG from "../../../assets/img/photos/team-2.webp";

export default function TeamBanner() {
    return(
        // <section className="wrapper bg-soft-primary">
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400" style={{backgroundPosition: "bottom"}} data-image-src={BG}>
            <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-3 text-white">Our Team</h1>
                        <p className="lead px-lg-5 px-xxl-8 text-white">Think unique and be innovative. Make a difference with InfyU LABS.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}