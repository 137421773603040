import { useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import NewsBanner from "./NewsHeader";
import NewsPage from "./NewsPage";
import { motion } from "framer-motion";

export default function News() {
    const [pageNo, setPageNo] = useState(1);
    const [page, setPage] = useState();

    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                <NewsBanner />
                <section className='wrapper bg-white'>
                    <div className='col-md-8 col-lg-8 mx-auto'>
                        <p className='px-lg-5 px-xxl-8 mt-2 px-3 pt-8'>
                            Read comprehensive up-to-date news coverage of InfyU
                            LABS in digital, print and social media and discover
                            the agritech world through the eyes of InfyU LABS.
                            It's a one-stop-shop for complete analysis and
                            updates of the agriculture and technology realm
                            giving you access to unlimited news and recent
                            happenings.
                        </p>
                    </div>
                </section>

                <div className='container pb-14 pb-md-16 pt-5'>
                    <div className='row'>
                        <div className='col-lg-9 mx-auto'>
                            <div className='blog grid grid-view'>
                                <div className='row isotope gx-md-8 gy-8 mb-8'>
                                    <NewsPage page={pageNo} />
                                </div>
                            </div>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                {/* <button className={`btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end ${pageNo===1? "disabled" : ""}`} 
                                    onClick={() => {
                                        if(pageNo>0){
                                            setPageNo(pageNo-1)
                                        }
                                    }}
                                >
                                    <i className="uil uil-arrow-left"></i>
                                </button>
                                <p className='my-2 mx-4'>{pageNo}</p>
                                <button className={`btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end ${pageNo===3? "disabled" : ""}`}
                                        onClick={() => {setPageNo(pageNo+1)}}>
                                    <i className="uil uil-arrow-right"></i>
                                </button> */}

                                <button
                                    className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${
                                        pageNo === 1
                                            ? "btn-outline-primary"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setPageNo(1);
                                    }}
                                >
                                    1
                                </button>
                                <button
                                    className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${
                                        pageNo === 2
                                            ? "btn-outline-primary"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setPageNo(2);
                                    }}
                                >
                                    2
                                </button>
                                <button
                                    className={`btn btn-sm btn-soft-ash btn-icon btn-icon-end mx-2 ${
                                        pageNo === 3
                                            ? "btn-outline-primary"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setPageNo(3);
                                    }}
                                >
                                    3
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </motion.div>
    );
}
