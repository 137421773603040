import Footer from '../Common/Footer';
import Header from '../Common/Header';

export default function Credits(){
    return(
        <div className="content-wrapper">
            <Header/>
            <section className='wrapper bg-soft-primary'>
                <div className='container pt-8 pb-10 pt-md-13 pb-md-15 text-center'>
                    <div className='row'>
                        <div className='col-md-7 col-lg-6 col-xl-5 mx-auto'>
                            <h1 className='display-1'>Credits</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className='wrapper bg-white'>
                <div className='col-md-8 col-lg-8 mx-auto px-6'>
                    <a href="https://www.freepik.com/vectors/website">Website vector created by stories - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/background">Background vector created by rawpixel.com - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/technology">Technology vector created by stories - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/abstract">Abstract vector created by macrovector - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/food">Food vector created by pch.vector - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/business">Business vector created by pikisuperstar - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/email-notification">Email notification vector created by vectorjuice - www.freepik.com</a><br />
                    <a href="https://www.freepik.com/vectors/business">Business vector created by pikisuperstar - www.freepik.com</a><br />
                </div>
            </section>

            <Footer/>
        </div>
    )
}