export default {
    "Apple": [
        {
            "Firmness": "19.35",
            "Brix": "11.06",
            "pH": "4.81",
            "Starch": "4.04",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a1.png"
        },
        {
            "Firmness": "17.13",
            "Brix": "10.65",
            "pH": "4.88",
            "Starch": "3.88",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a2.png"
        },
        {
            "Firmness": "17.52",
            "Brix": "10.79",
            "pH": "4.46",
            "Starch": "3.73",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a3.jpg"
        },
        {
            "Firmness": "20.15",
            "Brix": "10.68",
            "pH": "6.39",
            "Starch": "4.18",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a4.png"
        },
        {
            "Firmness": "19.3",
            "Brix": "10.65",
            "pH": "6",
            "Starch": "3.56",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a5.jpg"
        },
        {
            "Firmness": "22.79",
            "Brix": "11.84",
            "pH": "6.23",
            "Starch": "3.99",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a6.png"
        },
        {
            "Firmness": "11.8",
            "Brix": "9.32",
            "pH": "6.4",
            "Starch": "2.53",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a7.png"
        },
        {
            "Firmness": "16.82",
            "Brix": "13.25",
            "pH": "6.32",
            "Starch": "3.49",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a8.jpg"
        },
        {
            "Firmness": "13.24",
            "Brix": "9.23",
            "pH": "5.62",
            "Starch": "4",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a9.png"
        },
        {
            "Firmness": "17.9",
            "Brix": "14.85",
            "pH": "5.19",
            "Starch": "3.01",
            "Defect": "Ok",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a10.jpg"
        },
        {
            "Firmness": "17.9",
            "Brix": "12.94",
            "pH": "5.01",
            "Starch": "3.18",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a11.png"
        },
        {
            "Firmness": "15.05",
            "Brix": "16.12",
            "pH": "5.54",
            "Starch": "3.24",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a12.png"
        },
        {
            "Firmness": "15",
            "Brix": "8.34",
            "pH": "4.19",
            "Starch": "3.39",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a13.jpg"
        },
        {
            "Firmness": "14.3",
            "Brix": "9.19",
            "pH": "3.99",
            "Starch": "3.39",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a14.jpg"
        },
        {
            "Firmness": "11.85",
            "Brix": "9.24",
            "pH": "3.56",
            "Starch": "3.61",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a15.jpg"
        },
        {
            "Firmness": "9.4",
            "Brix": "8.77",
            "pH": "3.37",
            "Starch": "2.82",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a16.jpg"
        },
        {
            "Firmness": "13.97",
            "Brix": "10.91",
            "pH": "4.2",
            "Starch": "4.15",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a17.jpg"
        },
        {
            "Firmness": "17.12",
            "Brix": "9.78",
            "pH": "3.72",
            "Starch": "2.91",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a18.jpg"
        },
        {
            "Firmness": "18.12",
            "Brix": "10.39",
            "pH": "5.62",
            "Starch": "3.07",
            "Defect": "Defect",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a19.jpg"
        },
        {
            "Firmness": "13.56",
            "Brix": "12.5",
            "pH": "5.5",
            "Starch": "3.12",
            "Defect": "Defect",
            "Images": " https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Apple_Images/a20.jpg"
        }
    ],
    "Pomegranate": [
        {
            "Firmness": "12.54",
            "Brix": "15",
            "pH": "4.78",
            "Color": "80%",
            "Dry matter": "31.7",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/1.jpg"
        },
        {
            "Firmness": "7.23",
            "Brix": "14.26",
            "pH": "4.67",
            "Color": "80%",
            "Dry matter": "30.12",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/2.jpg"
        },
        {
            "Firmness": "9.88",
            "Brix": "14.56",
            "pH": "3.89",
            "Color": "80%",
            "Dry matter": "25.17",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/3.jpg"
        },
        {
            "Firmness": "11.25",
            "Brix": "12.27",
            "pH": "3.99",
            "Color": "80%",
            "Dry matter": "25.49",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/4.jpg"
        },
        {
            "Firmness": "9.9",
            "Brix": "13.84",
            "pH": "4.1",
            "Color": "80%",
            "Dry matter": "28.84",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/5.png"
        },
        {
            "Firmness": "9.03",
            "Brix": "1467",
            "pH": "4.11",
            "Color": "80%",
            "Dry matter": "30.89",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/6.png"
        },
        {
            "Firmness": "9.67",
            "Brix": "11.99",
            "pH": "4.88",
            "Color": "80%",
            "Dry matter": "26.65",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/7.png"
        },
        {
            "Firmness": "10.91",
            "Brix": "11.03",
            "pH": "5.02",
            "Color": "80%",
            "Dry matter": "31.15",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/8.png"
        },
        {
            "Firmness": "11.87",
            "Brix": "12.29",
            "pH": "4.93",
            "Color": "80%",
            "Dry matter": "28.25",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/9.png"
        },
        {
            "Firmness": "10.34",
            "Brix": "15.76",
            "pH": "4.69",
            "Color": "70%",
            "Dry matter": "30.21",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/10.png"
        },
        {
            "Firmness": "9.94",
            "Brix": "13.09",
            "pH": "5.15",
            "Color": "80%",
            "Dry matter": "26.62",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/11.jpg"
        },
        {
            "Firmness": "9.74",
            "Brix": "13.35",
            "pH": "5.2",
            "Color": "80%",
            "Dry matter": "29.59",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/12.jpg"
        },
        {
            "Firmness": "10.17",
            "Brix": "13.89",
            "pH": "5.06",
            "Color": "80%",
            "Dry matter": "30.01",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/13.jpg"
        },
        {
            "Firmness": "8.94",
            "Brix": "14.5",
            "pH": "5.12",
            "Color": "80%",
            "Dry matter": "25.63",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/14.jpg"
        },
        {
            "Firmness": "9.39",
            "Brix": "14.84",
            "pH": "5.01",
            "Color": "80%",
            "Dry matter": "25.99",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/15.jpg"
        },
        {
            "Firmness": "9.15",
            "Brix": "15.06",
            "pH": "5.28",
            "Color": "80%",
            "Dry matter": "34.45",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/16.jpg"
        },
        {
            "Firmness": "9.82",
            "Brix": "14.53",
            "pH": "5.1",
            "Color": "80%",
            "Dry matter": "32.55",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/17.jpg"
        },
        {
            "Firmness": "12.11",
            "Brix": "14.51",
            "pH": "4.81",
            "Color": "80%",
            "Dry matter": "28.82",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/18.jpg"
        },
        {
            "Firmness": "9.21",
            "Brix": "13.61",
            "pH": "4.89",
            "Color": "80%",
            "Dry matter": "29.23",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/19.jpg"
        },
        {
            "Firmness": "7.8",
            "Brix": "11.32",
            "pH": "5.02",
            "Color": "80%",
            "Dry matter": "32.01",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pomegranate_Images/20.png"
        }
    ],
    "Orange": [
        {
            "Firmness": "4.21",
            "Brix": "9.2",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/1.jpg"
        },
        {
            "Firmness": "6.77",
            "Brix": "10.67",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/2.jpg"
        },
        {
            "Firmness": "5.87",
            "Brix": "9.69",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/3.jpg"
        },
        {
            "Firmness": "5.25",
            "Brix": "9.76",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/4.png"
        },
        {
            "Firmness": "3.66",
            "Brix": "10.67",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/5.png"
        },
        {
            "Firmness": "6.36",
            "Brix": "9.62",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/6.png"
        },
        {
            "Firmness": "4.64",
            "Brix": "10.93",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/7.jpg"
        },
        {
            "Firmness": "6.5",
            "Brix": "11.35",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/8.jpg"
        },
        {
            "Firmness": "4.88",
            "Brix": "10.15",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/9.jpg"
        },
        {
            "Firmness": "3.66",
            "Brix": "10.07",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/10.jpg"
        },
        {
            "Firmness": "6.11",
            "Brix": "9.64",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/11.jpg"
        },
        {
            "Firmness": "5.87",
            "Brix": "9.69",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/12.jpg"
        },
        {
            "Firmness": "3.83",
            "Brix": "9.69",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/13.jpg"
        },
        {
            "Firmness": "3.96",
            "Brix": "10.37",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/14.jpg"
        },
        {
            "Firmness": "4.76",
            "Brix": "9.3",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/15.jpg"
        },
        {
            "Firmness": "5.39",
            "Brix": "12.19",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/16.jpg"
        },
        {
            "Firmness": "5.03",
            "Brix": "12.62",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/17.jpg"
        },
        {
            "Firmness": "4",
            "Brix": "12.32",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/18.jpg"
        },
        {
            "Firmness": "5.3",
            "Brix": "10.44",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/19.jpg"
        },
        {
            "Firmness": "5.32",
            "Brix": "11.22",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Orange_Images/20.jpg"
        }
    ],
    "Pear": [
        {
            "Firmness": "5.36",
            "Brix": "13.75",
            "pH": "5.46",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/1.png"
        },
        {
            "Firmness": "3.08",
            "Brix": "10.91",
            "pH": "5.28",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/2.png"
        },
        {
            "Firmness": "6.62",
            "Brix": "15",
            "pH": "5.25",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/3.png"
        },
        {
            "Firmness": "5.39",
            "Brix": "11.69",
            "pH": "5.07",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/4.png"
        },
        {
            "Firmness": "6.78",
            "Brix": "11.24",
            "pH": "5.12",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/5.jpg"
        },
        {
            "Firmness": "3.87",
            "Brix": "10.28",
            "pH": "5.49",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/6.png"
        },
        {
            "Firmness": "6.9",
            "Brix": "10.95",
            "pH": "5.24",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/7.jpg"
        },
        {
            "Firmness": "4.88",
            "Brix": "11.52",
            "pH": "5.43",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/8.jpg"
        },
        {
            "Firmness": "5.65",
            "Brix": "15.18",
            "pH": "5.5",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/9.jpg"
        },
        {
            "Firmness": "5.19",
            "Brix": "8.94",
            "pH": "5.24",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/10.jpg"
        },
        {
            "Firmness": "4.37",
            "Brix": "13.31",
            "pH": "4.07",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/11.jpg"
        },
        {
            "Firmness": "3.15",
            "Brix": "10.54",
            "pH": "3.8",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/12.jpg"
        },
        {
            "Firmness": "4.31",
            "Brix": "12.36",
            "pH": "4.45",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/13.jpg"
        },
        {
            "Firmness": "3.71",
            "Brix": "15.05",
            "pH": "4.53",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/14.png"
        },
        {
            "Firmness": "4.33",
            "Brix": "13.17",
            "pH": "4.36",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/15.jpg"
        },
        {
            "Firmness": "3.42",
            "Brix": "12.55",
            "pH": "4.66",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/16.jpg"
        },
        {
            "Firmness": "4.99",
            "Brix": "12.39",
            "pH": "4.16",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/17.jpg"
        },
        {
            "Firmness": "2.86",
            "Brix": "13.21",
            "pH": "4.23",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/18.jpg"
        },
        {
            "Firmness": "2.18",
            "Brix": "12.1",
            "pH": "4.66",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/19.png"
        },
        {
            "Firmness": "3.01",
            "Brix": "13.2",
            "pH": "4.77",
            "Images": "https://infyulabs-development.s3.ap-south-1.amazonaws.com/Fruit_Image/Pear_Images/20.jpg"
        }
    ],
};