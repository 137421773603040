import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { motion } from "framer-motion";

import BG from "../../../assets/img/photos/retailers.webp";
import retailer from "../../../assets/img/Retailer.png";

export default function Retail() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='content-wrapper'>
                <Header />
                {/* <section className="wrapper bg-soft-primary"> */}
                <section
                    className='wrapper image-wrapper bg-image bg-overlay bg-overlay-400'
                    data-image-src={BG}
                    style={{ backgroundPosition: "bottom" }}
                >
                    <div className='container pt-16 pb-16 pt-md-16 pb-md-16 text-center'>
                        <div className='row'>
                            <div className='col-md-7 col-lg-6 col-xl-5 mx-auto'>
                                <h1 className='display-1 mb-3 text-white'>
                                    Retailers
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='wrapper bg-white'>
                    <div className='col-md-8 col-lg-8 mx-auto'>
                        <div className=' py-2 mx-4'>
                            <div className='row gy-10 mt-0 align-items-center'>
                                <div className='col-lg-8'>
                                    <h3 className='display-6 mb-4'>
                                        InfyU LABS Aiding Retailers
                                    </h3>
                                    <p className='mb-5'>
                                        Quality is gradually becoming one of the
                                        most important criteria for the end
                                        consumer. The importance of quality
                                        among the end consumer owing to health
                                        awareness and pandemic is driving
                                        retailers to source the best quality
                                        produce.
                                    </p>
                                    <p className='mb-5'>
                                        Retailers can make the most of InfyU
                                        LABS quality assurance solution.
                                    </p>
                                </div>
                                <div className='col-lg-4 position-relative'>
                                    <figure className='rounded mb-0'>
                                        <img
                                            src={retailer}
                                            srcSet={retailer}
                                            alt=''
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className='py-2 mx-4'>
                            <h3 className='display-6 mb-4'>
                                Fair Prices Lead to Happy Farmers
                            </h3>
                            <p className='mb-5'>
                                At times there is disconnect and disagreement on
                                rates between farmers and retailers as they do
                                not agree on product’s quality and life.
                            </p>
                            <p className='mb-5'>
                                Infyzer can be used to assess factors like
                                ripeness, uniformity, firmness, and freshness
                                and shelf life accurately.
                            </p>
                            <p className='mb-5'>
                                This can not only facilitate the sale at fair
                                price but also helps develop trust, transparency
                                and fairness between farmers and retailers. By
                                merely tapping on their phone screens they can
                                have a customized quality report as per their
                                need on Infyzer’s application.
                            </p>
                            <p className='mb-5'>
                                When retailers buy the produce from farmers they
                                can carry out quick hasslefree quality checks on
                                the spot using Infyzer and obtain customized
                                quality reports as per their need. It is a
                                win-win situation for everyone, because
                                retailers buy good quality products with
                                standardized reports, and in return end
                                consumers obtain good quality products.
                            </p>
                            <p className='mb-5'>
                                Additionally accurate shelf-life prediction via
                                Infyzer will make it easy to categorize produce
                                that will last longer and can be transported to
                                farther locations and the lot that need to be
                                sent to nearby local markets.
                            </p>
                            <p className='mb-5'>
                                Needless to say there is no need to wait hours
                                for test results.
                            </p>
                            <p className='mb-5'>
                                Retailers sell these products locally as well as
                                export them internationally. India is one of the
                                leading fresh fruit exporters in the world.
                            </p>
                            <p className='mb-5'>
                                A standard quality report with all relevant
                                parameters judged and determined, can help India
                                make a statement in the international export
                                market. Rejection of huge consignment from
                                importing countries can be reduced drastically
                                with help of quality.
                            </p>
                            <p className='mb-5'>
                                The World Trade Organization's sanitary and
                                phytosanitary (SPS) agreement encourages member
                                countries to recognize each other’s conformity
                                assessment systems based on international
                                standards so that products certified in one
                                country are accepted without the need for
                                further testing by other countries.
                            </p>
                            <p className='mb-5'>
                                There are certain fruits that are difficult to
                                judge based on look and feel as they maintain
                                consistent external texture throughout their
                                life. This leaves room for inaccuracy and errors
                                if quality inspection is done manually. Precise
                                quality reports can bridge this gap.
                            </p>
                            <p className='mb-5'>
                                Meanwhile, Agriculture is the primary source of
                                food and by extension, life. It is necessary to
                                make the most of available technological
                                resources to complement agriculture and its
                                primary players.
                            </p>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </motion.div>
    );
}
