
import One from '../../../assets/img/phone-plant-2.webp';
import Two from '../../../assets/img/plant-hand-2.webp';
import Toil from '../../../assets/img/Infyzer-toil.webp';
import noMagic from '../../../assets/img/Infyzer-no-magic.webp';
import tell from '../../../assets/img/Infyzer-tell.webp';
// import { useForm } from '../../hooks/useForm';
// import thumbNail from '../../../assets/img/screen.png';




export default function ProductContent() {

    return (
        <>
            <div className="classic-view">
                <article className="post">
                    <div className="post-content">
                        {/* <h2 className="h1 mb-4">Infyzer: Lab in a pocket</h2> */}
                         

                        <div className='item light-gallery-wrapper'>
                            <div className="video"
                                style={{
                                    position: "relative",
                                    paddingBottom: "56.25%" /* 16:9 */,
                                    paddingTop: 30,
                                    height: 0,
                                }}>
                                <iframe
                                    className='mx-auto'
                                    src="https://www.youtube.com/embed/g4qUoFoIrEY"
                                    allowFullScreen
                                    frameBorder={0}
                                    // style={{borderRadius: "0.5rem"}}
                                    title="infyzer"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "0.5rem"
                                      }}
                                    loading="lazy"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                />
                            </div>
                            {/* <video
                                poster={thumbNail}
                                className='player'
                                playsInline
                                controls
                                preload='none'
                            >
                                <source
                                    src='https://www.infyulabs.com/images/1634121178433-WhatsApp Video 2021-08-28 at 3.38.23 PM.mp4'
                                    type='video/mp4'
                                />
                            </video> */}
                        </div>
                        <p className='pt-5'>
                        We gladly introduce to you a portable hand held device that can change the dynamics of testing forever. Infyzer is a small device that can test fruits and vegetables within seconds, hasslefree. It is a mini magic box for all your food safety and quality problems.
                        </p>
                        <p>
                        As opposed to current lab testing methods, Infyzer non-destructively checks chemical composition of the product in a timely and accurate manner. 
                        </p>

                        <section className="wrapper bg-light">
                            <div className=" py-8">
                                <div className="row gy-10 mt-0 align-items-center">
                                    <div className="col-lg-4 order-lg-2 position-relative">
                                        <div className="shape bg-line aqua rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ top: '-2rem', left: '-0.6rem' }} />
                                        <div className="shape bg-pale-red rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ bottom: '-2rem', right: '-0.4rem' }} />
                                        <figure className="rounded mb-0"><img src={tell} srcSet={tell} alt="" /></figure>
                                    </div>
                                    <div className="col-lg-8">
                                        <h3 className="display-6 mb-4">What Can Infyzer Tell you?</h3>
                                        <p className="mb-5">
                                        Infyzer not only assesses the external factors like appearance, uniformity, maturity and freshness of fruits but also internal parameters that helps understand the true quality of the subject under test.
                                        </p>
                                        <p className="mb-5">
                                        It tells everything about a product's chemical composition you can possibly think of. Internal quality factors include the taste, the texture,the nutrition and the lack of biotic and abiotic contaminants in fruits and vegetables.
                                        </p>
                                        
                                    </div>
                                    {/*/column */}
                                </div>
                            </div>
                        </section>
                        <p>Below parameters are presented as a result of testing via Infyzer:</p>
                        <ul className='row'>
                            <div className="col-md">
                                <li>TSS (BRIX)</li>
                                <li>Acidity (pH)</li>
                                <li>Maturity Level Content</li>
                                <li>Starch Index</li>
                                <li>Water Core</li>
                                <li>Chilling Effect</li>
                                <li>Internal Color (Pomegranate)</li>
                            </div>
                            <div className="col-md">
                                <li>Firmness</li>
                                <li>Shelf Life</li>
                                <li>Ripeness</li>
                                <li>Internal Spoilage</li>
                                <li>Moisture Content</li>
                                <li>Dry Matter</li>
                                <li>SHU Level (Red Chili)</li>
                            </div>
                        </ul>
                        <p>This handheld device facilitates automatic grading and sorting of fruits, according to the chemical composition to fetch a high value in the market. </p>
                        
                        <section className="wrapper bg-light">
                            <div className=" py-8">
                                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-6 align-items-center">
                                    <div className="col-lg-4 order-lg-2 position-relative">
                                        <div className="shape bg-line aqua rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ top: '-2rem', left: '-0.6rem' }} />
                                        <div className="shape bg-pale-red rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ bottom: '-2rem', right: '-0.4rem' }} />
                                        <figure className="rounded mb-0"><img src={Toil} srcSet={Toil} alt="" /></figure>
                                    </div>
                                    <div className="col-lg-8">
                                        <h3 className="display-6 mb-4">Goodbye Toil and Turmoil</h3>
                                        <p className="mb-5">
                                        Conventional and manual methods of food grading are expensive, tiresome, and require manual labor. We bring to you a hassle-free and quick quality inspection through Infyzer.
                                        </p>
                                        <p className="mb-5">
                                        Say goodbye to the transportation hassle of sending your product to the lab, hefty testing cost, rott and damage risks, destructive testing and long wait time.
                                        </p>
                                        <p className="mb-5">
                                        With Infyzer you don't need to spend on transport or wait for results. You just wave the magic Infyzer wand and sit back and relax till you see customized reports describing the health of a fruit or a vegetable.
                                        </p>
                                    </div>
                                </div>
                                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-6 align-items-center">
                                    <div className="col-lg-4 position-relative">
                                        <div className="shape bg-line leaf rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ top: '-2rem', right: '-0.6rem' }} />
                                        <div className="shape bg-pale-violet rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ bottom: '-2rem', left: '-0.4rem' }} />
                                        <figure className="rounded mb-0"><img src={One} srcSet={One} alt="" /></figure>
                                    </div>
                                    <div className="col-lg-8">
                                        <h5 className="display-6 mb-4">Not An Expert? Don’t Worry We Got You Covered..
                                        </h5>
                                        <p className="mb-5">
                                        Have you had to watch endless how-to-use tutorials and surf google for hours before you get a hang of it? Not the same with Infyzer. It gives you a seamless and smooth user experience. Device is connected to a user-friendly app on your mobile phone via bluetooth. 
                                        </p>
                                        <p className='mb-5'>
                                        You can store, track and analyze all the reports at one place in the app. All you have to do is point Infyzer to a fruit or vegetable and VOILA! within a few seconds you get everything there is to know. 
                                        </p>
                                        <p className="mb-5">
                                        You can get going without the involvement of trained lab technicians and skilled testers. Accuracy and transparency are inbuilt in our results. 
                                        </p>
                                    </div>
                                </div>
                                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-6 align-items-center">
                                    <div className="col-lg-4 order-lg-2 position-relative">
                                        <div className="shape bg-line aqua rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ top: '-2rem', left: '-0.6rem' }} />
                                        <div className="shape bg-pale-red rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ bottom: '-2rem', right: '-0.4rem' }} />
                                        <figure className="rounded mb-0"><img src={Two} srcSet={Two} alt="" /></figure>
                                    </div>
                                    <div className="col-lg-8">
                                        <h3 className="display-6 mb-4">Food For Thought</h3>
                                        <p className="mb-5">
                                        One of the most prominent limitations of conventional food testing techniques is that it causes heavy produce wastage due to destructive testing methods. 
                                        </p>
                                        <p className="mb-5">
                                        Food waste also emerges from rotting of produce because of the long turnaround time it takes to complete testing.                                         </p>
                                        <p className="mb-5">
                                        Infyzer eliminates the need for shipment and makes sure that you retain most part of your stock. It guarantees minimal wastage as it is based on the underlying principle of non-destructive and non-invasive food grading.
                                        </p>
                                    </div>
                                    {/*/column */}
                                </div>
                                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-6 align-items-center">
                                    <div className="col-lg-4 position-relative">
                                        <div className="shape bg-line leaf rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ top: '-2rem', right: '-0.6rem' }} />
                                        <div className="shape bg-pale-violet rounded-circle rellax w-17 h-17" data-rellax-speed={1} style={{ bottom: '-2rem', left: '-0.4rem' }} />
                                        <figure className="rounded mb-0"><img src={noMagic} srcSet={noMagic} alt="" /></figure>
                                    </div>
                                    <div className="col-lg-8">
                                        <h5 className="display-6 mb-4">It's No Magic But All Technology
                                        </h5>
                                        <p className="mb-5">
                                        Infyzer, uses the principle of spectroscopy powered by Machine learning to test fruits and vegetables within seconds.
                                        </p>
                                        <p className='mb-5'>
                                        A harmless infrared radiation is sent out by Infyzer that will penetrate upto 5 cm into the fruit, interact with its internal composition and this will send back a response signal.
                                        </p>
                                        <p className="mb-5">
                                        The response signal is then sent to the cloud server. There are various deep learning analysis models on the cloud server which will provide fruit’s health, chemical composition and other interdependent factor’s report. Backed by AI applications Infyzer is your perfect quality check buddy.
                                        </p>
                                    </div>
                                </div>
                                {/*/.row */}
                            </div>
                            {/* /.container */}
                        </section>
                        
                        {/* <div className="clearfix">
                            <section style={{ marginBottom: '20px' }} className="mb20 text-center">
                                <div className="row" style={{ lineHeight: '1.4' }}>
                                    <div className="col-md-12 mb20" style={{ lineHeight: '1.4', marginBottom: '20px' }}>
                                        <h2 className="text-center" style={{ lineHeight: '1.4' }}><span style={{ fontSize: '24px' }}><u>Give Your Food Grading System The Power of IoT and Deep Learning</u></span></h2>
                                    </div>
                                    <div className="col-md-6" style={{ lineHeight: '1.4' }}>
                                        <img alt="tt" src="https://infyulabs.com/images/1598954415781-time.png" />
                                        <h5 style={{ margin: '20px 0', fontSize: '24px' }}>Get Instant, Hassle-Free Results</h5>
                                        <p style={{ textAlign: 'center' }}>Conventional and manual methods of food grading are expensive, tiresome and require manual labour. Experience a complete hassle-free and quick quality inspection through Infyzer's handheld device. It's IoT based technology helps you to grade food items within seconds and that too non invasively. </p>
                                    </div>
                                    <div className="col-md-6">
                                        <img alt="vv" src="https://infyulabs.com/images/1598954374024-expert.png" />
                                        <h5 style={{ margin: '20px 0', fontSize: '24px' }}>Need Not Be A Expert</h5>
                                        <p>You need not bang your head understanding the technology and watch endless how-to tutorials to understand the working principles of Infyzer. It gives you a smooth experience and lets the technology behind it do all the work. Get going without the involvement of trained lab technicians and skilled testers. Infyzer ensures instant results, thus eliminating the need to wait for long hours for results.</p>
                                        <p> </p>
                                    </div>
                                </div>
                                <div style={{ background: '#fff', padding: '50px 0' }} className="row">
                                    <div className="col-md-6">
                                        <img alt="mmm" src="https://infyulabs.com/images/1598954394141-logistics.png" />
                                        <h5 style={{ margin: '20px 0', fontSize: '24px' }}>Get Rid Of Hefty Transportation Costs and Produce Damage</h5>
                                        <p>Transportation of bulky produce may cost you bombs, and it always poses a risk of damaging the produce. But, with InfyZer, you don't need to spend on transport anywhere. The handheld, the portable device is capable of fast food grading through cloud-enabled technology. It uses your mobile device's connection and sends accessible data on the cloud.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img alt="nf" src="https://infyulabs.com/images/1598954328570-waste.png" />
                                        <h5 style={{ margin: '20px 0', fontSize: '24px' }}>Ensure Minimal Wastage and Destruction</h5>
                                        <p>Conventional food testing techniques cause heavy produce wastage and destruction. Infyzer eliminates the need for shipment and makes sure that you retain most part of your stock. It guarantees minimal wastage as it is based on the underlying principle of non-destructive food grading.</p>
                                    </div>
                                </div>
                            </section>
                            <div style={{ marginBottom: '20px', display: "flex", alignItems: "center" }} className="row mb20">
                                <div className="col-md-4">
                                    <img alt="gd" style={{ maxWidth: '100%', width: 'auto' }} src="https://www.infyulabs.com/images/1598951144939-fruitmeasurement.webp" />
                                </div>
                                <div className="col-md-8">
                                    <h5 style={{ margin: '20px 0', fontSize: '24px' }}>How We Use Technology To Make Invasion Free Grading Possible?</h5>
                                    <p>Infyzer works on the underlying principle of Spectroscopy, one of the most promising techniques to determine the quality of forage, grains, fruits, vegetables and other agricultural products.
                                        Coupled with the power of deep learning techniques and algorithms, Infyzer is the one-stop solution for long-prevailing food safety problems.</p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    <button className="btn btn-primary" style={{ margin: "0 auto 0 auto", position: "absolute", bottom: "-80px", right: "40%" }} data-toggle="modal" data-target="#form" to="/">Interested</button>

                </article>
            </div>
        </>
    );
}