import "../../../assets/css/cursor.scss";
import React, { useEffect, useRef, useState } from "react";
// import Bg from '../../../assets/img/photos/home-banner-2.png';
// import Bg from "../../../assets/img/photos/home-22.png";
// import Bg from '../../../assets/img/photos/bg100-d1.jpg';
import "../../../assets/css/firstToSee.css";
import "../../../assets/css/homeHotSpots.css";

// import Dev from "../../../assets/img/screen.png";
import $ from "jquery";
import DemoList from "./DemoList";
import DemoListItem from "./DemoListItem";

export default function FirstToSee() {
    const pageStack = useRef([]);

    const [isItems, setIsItems] = useState({ item: {}, isVis: false });
    useEffect(() => {
        $(".playButton").on("click", () => {
            // $('.listItemsContainer').toggle();
            pageStack.current.forEach((e, idx) => {
                if (idx > 0) {
                    if (idx === 1) {
                        setIsItems({ item: "", isVis: false });
                    } else $(e).hide();
                }
            });
            $(".mainDivDemo").show();
            $(".demoContainer").show();
            pageStack.current = [".listItemsContainer"];
            $(pageStack.current[0]).show();
            $(".demoListLi")
                .delay(500)
                .each(function (i) {
                    $(this)
                        .delay(100 * i)
                        .queue(function () {
                            $(this).addClass("showList");
                        });
                    $(this).dequeue();
                });
            if ($(".mainDivDemo").is(":visible")) {
                $("html,body").css({
                    overflow: "hidden",
                    height: "100%",
                });
            }
        });
        $(".mainDivDemo,.closeBut").on("click", () => {
            $(".mainDivDemo").hide();
            $(".demoListLi").removeClass("showList");

            $(".demoContainer").hide();
            $("html,body").css({
                overflow: "auto",
                height: "auto",
            });
            $(".cursor").hide();
            $(".demoContainer").css({
                cursor: "default",
            });
        });

        return function () {
            $(".playButton").off("click");
        };
    }, []);

    // useEffect(() => {console.log(pageStack.current.length);})

    const backButton = () => {
        if (pageStack.current.length === 2) {
            $(".cursor").hide();
            $(".demoContainer").css({
                cursor: "default",
            });
        }
        if (pageStack.current.length === 1 || pageStack.current.length === 3) {
            $(".mainDivDemo").hide();
            $(".demoListLi").removeClass("showList");
            $(".demoContainer").hide();
            $("html,body").css({
                overflow: "auto",
                height: "auto",
            });
            return;
        }

        $(pageStack.current[pageStack.current.length - 1]).hide();
        pageStack.current.pop();
        $(pageStack.current[pageStack.current.length - 1]).show();
    };

    return (
        <>
            <div
                className='mainDivDemo'
                style={{ display: "none", zIndex: "1090" }}
            >
                <i
                    style={{
                        position: "absolute",
                        right: "50px",
                        top: "25px",
                        cursor: "pointer",
                        zIndex: "10000",
                    }}
                    className='far closeBut fa-times-circle fa-3x'
                    aria-hidden='true'
                ></i>
            </div>

            <div className='demoContainer' style={{ display: "none" }}>
                {/* <div style={{width: "100%", backgroundColor: "blue", display: "flex", flexDirection: "row"}}>
                    <i onClick={backButton} className="fas fa-arrow-left backBut" style={{ fontSize: '1.1rem', position: 'absolute', left: 20, top: 20, cursor: 'pointer' }}></i>
                    <h2>Lorem, ipsum dolor.</h2>
                </div> */}
                <i
                    onClick={backButton}
                    className='fas fa-arrow-left backBut'
                    style={{
                        fontSize: "1.1rem",
                        position: "absolute",
                        left: 20,
                        top: 20,
                        cursor: "pointer",
                    }}
                ></i>
                <div className='progress' style={{ display: "none" }}>
                    <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{ width: "100%", height: "10px" }}
                        aria-valuenow='10'
                        aria-valuemin='0'
                        aria-valuemax='100'
                    ></div>
                </div>
                <DemoList
                    pageStack={pageStack}
                    setItems={setIsItems}
                    isItems={isItems}
                />
                {isItems.isVis && (
                    <DemoListItem pageStack={pageStack} item={isItems.item} />
                )}
                {/* <Report /> */}
                <div className='reportContainer' style={{ display: "none" }}>
                    <div id='charts'>
                        <div id='chartHolder1'>
                            <canvas id='chart1'></canvas>
                        </div>
                        <div id='chartHolder2'>
                            <canvas id='chart2'></canvas>
                        </div>
                        <div id='chartHolder3'>
                            <canvas id='chart3'></canvas>
                        </div>
                        <div id='chartHolder4'>
                            <canvas id='chart4'></canvas>
                        </div>
                        <div id='chartHolder5'>
                            <canvas id='chart5'></canvas>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section data-image-src={Bg} className=" wrapper bg-image bg-overlay bg-overlay-400 bg-content text-white"> */}
            {/* <section data-image-src="http://www.wildlife-photography-tips.com/images/black-and-white-nature-photography-stag.jpg" className="containR wrapper bg-image bg-overlay bg-overlay-400 bg-content text-white"> */}
            <div className='containR'>
                <div className="point p-orange">
                    <div className='fruitInfo f-orange'>
                        <p><strong>Brix: </strong>12</p>
                        <p><strong>Firmness: </strong>3</p>
                        <p><strong>Shelf Life: </strong>4 days</p>
                    </div>
                </div>

                <div className="point p-banana">
                    <div className='fruitInfo f-banana'>
                        <p><strong>Brix: </strong>18</p>
                        <p><strong>Firmness: </strong>3.5</p>
                        <p><strong>Shelf Life: </strong>3 days</p>
                    </div>
                </div>

                <div className="point p-apple">
                    <div className='fruitInfo f-apple'>
                        <p><strong>Brix: </strong>12</p>
                        <p><strong>Firmness: </strong>16</p>
                        <p><strong>pH: </strong>5</p>
                        <p><strong>Starch: </strong>3.5</p>
                        <p><strong>Shelf Life: </strong>6 days</p>
                    </div>
                </div>

                <div className="point p-malta">
                    <div className='fruitInfo f-malta'>
                        <p><strong>Brix: </strong>10</p>
                        <p><strong>Firmness: </strong>5</p>
                        <p><strong>Shelf Life: </strong>5 days</p>
                    </div>
                </div>
                <div className='container pt-17 pb-17' /*style={{ "zIndex": 5, "position": "relative" }}*/>
                    <div className='row gx-0 gy-12 align-items-center'>
                        <div
                            className='col-md-10 offset-md-1 offset-lg-0 col-lg-6 content text-center text-lg-start'
                            data-cues='slideInDown'
                            data-group='page-title'
                            data-delay='600'
                        >
                            <h1 className='display-2 mb-5 text-white'>
                                INFYU LABS -
                                <br /> INNOVATING FUTURE
                                <br /> FOR AGRICULTURE
                            </h1>
                            <div
                                className='d-flex justify-content-center justify-content-lg-start'
                                data-cues='slideInDown'
                                data-group='page-title-buttons'
                                data-delay='900'
                            >
                                <span>
                                    <a
                                        href='/#'
                                        className='btn btn-lg btn-white me-2 playButton'
                                    >
                                        <i className='fa fa-youtube-play'></i>{" "}
                                        Virtual Demo
                                    </a>
                                </span>
                                <span>
                                    <a
                                        href='/contact#contactFrom'
                                        className='btn btn-lg btn-outline-white'
                                    >
                                        <i className='fa fa-youtube-play'></i>{" "}
                                        Schedule Demo
                                    </a>
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-5 offset-lg-1'>
                            {/*Carousel */}
                            {/* <div
                                className='basic-slider owl-carousel dots-over shadow-lg'
                                data-nav='true'
                                data-margin='5'
                            > */}
                            <div className='item'>
                                <div className='item light-gallery-wrapper'
                                    style={{
                                        position: "relative",
                                        paddingBottom: "56.25%" /* 16:9 */,
                                        paddingTop: 25,
                                        height: 0
                                    }}>
                                    <iframe
                                        src="https://www.youtube.com/embed/Rv8nATxpZeE?autoplay=1&mute=1"
                                        allowFullScreen
                                        frameBorder={0}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "0.5rem"
                                        }}
                                        loading='lazy'
                                        title="infyzer intro"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    />


                                    {/* <video
                                            poster={Dev}
                                            className='player'
                                            playsInline
                                            controls
                                            preload='none'
                                        >
                                            <source
                                                src='https://www.youtube.com/watch?v=g4qUoFoIrEY'
                                                type='video/mp4'
                                            />
                                        </video> */}
                                </div>
                            </div>
                            {/* </div> */}
                            {/*Carousel */}
                        </div>
                    </div>
                </div>
            </div>
            {/* </section> */}
        </>
    );
}
