import BG from '../../../assets/img/photos/blog.webp';



export default function BlogBanner() {
    return (
        // <section className="wrapper bg-soft-primary">
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-500" data-image-src={BG}>
            <div className="container pt-16 pb-16 pt-md-16 pb-md-16 text-center">
                <div className="row">
                    <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                        <h1 className="display-1 mb-3 text-white">Blogs</h1>
                        {/* <p className="lead px-lg-5 px-xxl-8">Welcome to our journal. Here you can find the latest company news and business articles.</p> */}
                    </div>
                </div>
            </div>
        </section>
    );
}