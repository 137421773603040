import BG from "../../../assets/img/photos/Products-3.png";

export default function ProductBanner() {
    return (
        <section
            className='wrapper image-wrapper bg-image bg-overlay bg-overlay-400'
            data-image-src={BG}
        >
            {/* <section className="wrapper bg-soft-primary"> */}
            <div className='container pt-10 pb-19 pt-md-14 pb-md-20 text-center'>
                <div className='row'>
                    <div className='col-md-10 col-xl-8 mx-auto'>
                        <div className='post-header'>
                            {/* <div className="post-category text-line">
                                <a href="/#" className="hover" rel="category">InfyU LABS</a>
                            </div> */}
                            <h1 className='display-1 mb-4 text-white'>
                                Infyzer: Lab in a pocket
                            </h1>
                            {/* <p className='className="lead px-lg-5 px-xxl-8'>
                                Lorem ipsum dolor sit amet.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
