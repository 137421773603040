import Iot from '../../../../assets/img/iot.webp';


export default function IotHeader(){
    return(
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-500 text-white" data-image-src={Iot}>
        <div className="container pt-8 pb-10 pt-md-13 pb-md-15 text-center">
            <div className="row">
                <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                {/* <a href="https://www.freepik.com/vectors/background">Background vector created by rawpixel.com - www.freepik.com</a> */}
                    <h1 className="display-1 mb-6 mt-6 text-white">Internet of Things - Modern Day Science of Making Objects Smarter</h1>
                    {/* <p className="lead px-lg-5 px-xxl-8"><strong>"We are dedicated to the democratization of food testing with a mission to make chemical-free quality fruits accessible to everyone."</strong></p> */}
                </div>
            </div>
        </div>
    </section>
    )
}