

import BG from '../../../assets/img/photos/contact.webp';


export default function ContactBanner() {
    return (
        <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white" data-image-src={BG} style={{backgroundPosition: "center"}}>
            <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <h1 className="display-1 mb-3 text-white">Get in Touch</h1>
                        <nav className="d-inline-block" aria-label="breadcrumb">
                            {/* <ol className="breadcrumb text-white">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact</li>
                            </ol> */}
                        </nav>
                        {/* /nav */}
                    </div>
                    {/* /column */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    );
}