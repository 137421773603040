import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Banner from "./Banner";
import ProductDetails from "./ProductDetails";
import { motion } from "framer-motion";

export default function InfyQR() {
    return (
        <motion.div
            // initial={{ opacity: 0.5, y: "100px" }}
            // animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0.5, y: "100px" }}
        >
            <div className='wrapper-content'>
                <Header />
                <Banner />

                <section className='wrapper bg-light'>
                    <div className='container pb-14 pb-md-16'>
                        <div className='row'>
                            <div className='col-lg-10 mx-auto'>
                                <div className='blog single mt-n17'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <ProductDetails />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </motion.div>
    );
}
