import One from "../../../assets/img/screen1.png";
import portalab from "../../../assets/img/Portalab.png";
import infyqr from "../../../assets/img/InfyQR.png";
// import { Link } from 'react-router-dom';

export default function ArticleBigView() {
    return (
        // <div className="blog classic-view mt-n17">
        //     <article className="post">
        //         <div className="card">
        //             <figure className="card-img-top overlay overlay1 hover-scale"><a className="line-dark" href="/infyzer"><img src={One} alt="" /></a>
        //                 <figcaption>
        //                     <h5 className="from-top mb-0">Read More</h5>
        //                 </figcaption>
        //             </figure>
        //             <div className="card-body">
        //                 <div className="post-header">
        //                     <div className="post-category text-line">
        //                         <a href="/#" className="hover" rel="category">infyulabs</a>
        //                     </div>
        //                     <h2 className="post-title mt-1 mb-0"><a className="link-dark" href="/infyzer">InfyZer</a></h2>
        //                 </div>
        //                 <div className="post-content">
        //                     <p>A handheld and non-destructive testing device to check chemical composition (such as pesticide levels, sugar levels, and other 10+ parameters) of fruits and vegetables.</p>
        //                 </div>
        //             </div>
        //         </div>
        //     </article >
        // </div >

        <div className='blog grid grid-view mt-8'>
            <div className='row isotope gx-md-8 gy-8 mb-8'>
                <article className='item post col-md-6'>
                    <div /*style={{ height: "520px" }}*/ className='card'>
                        <figure
                            style={{ height: "300px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='/infyzer'>
                                {" "}
                                <img src={One} alt='' />
                            </a>
                            <figcaption>
                                <h5 className='from-top mb-0'>Read More</h5>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <div className='post-header'>
                                <h2 className='post-title h3 mb-3'>
                                    <a className='link-dark' href='/infyzer'>
                                        InfyZer
                                    </a>
                                </h2>
                            </div>
                            <div className='post-content'>
                                <p
                                    style={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    A hand held and non-destructive testing
                                    device to check chemical composition (such
                                    as sugar levels, and other
                                    10+ parameters) of fruits and vegetables.
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
                <article className='item post col-md-6'>
                    <div /*style={{ height: "520px" }}*/ className='card'>
                        <figure
                            style={{ height: "300px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='/infyQR'>
                                {" "}
                                <img src={infyqr} alt='' />
                            </a>
                            <figcaption>
                                <h5 className='from-top mb-0'>Read More</h5>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <div className='post-header'>
                                <h2 className='post-title h3 mb-3'>
                                    <a className='link-dark' href='/infyQR'>
                                        InfyQR
                                    </a>
                                </h2>
                            </div>
                            <div className='post-content'>
                                <p
                                    style={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    With InfyQR, we bring in traceability which
                                    is one of the most important factors in the
                                    agricultural world. As people are getting
                                    aware of what they eat, traceability is an
                                    important to look at.
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
                <article className='item post col-md-6'>
                    <div /*style={{ height: "520px" }}*/ className='card'>
                        <figure
                            style={{ height: "300px" }}
                            className='card-img-top overlay overlay1 hover-scale'
                        >
                            <a href='/portalabs'>
                                {" "}
                                <img src={portalab} alt='' />
                            </a>
                            <figcaption>
                                <h5 className='from-top mb-0'>Read More</h5>
                            </figcaption>
                        </figure>
                        <div className='card-body'>
                            <div className='post-header'>
                                <h2 className='post-title h3 mb-3'>
                                    <a className='link-dark' href='/portalabs'>
                                        PortaLabs
                                    </a>
                                </h2>
                            </div>
                            <div className='post-content'>
                                <p
                                    style={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    PortaLabs service aims to offer easy and
                                    acccesible testing centers. Farmers,
                                    retailers or aggregators can easily approach
                                    these labs to get their product tested.
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
}
